import React, {useParams, useContext, useRef, useState, useEffect} from 'react';
import {} from 'react-router-dom';
import { Row, Col, Form, Input, Button, notification, Modal, Tabs } from 'antd';

import { Auth } from "../../utils/AuthContext";
import Firebase from '../../utils/firebaseConfig';

import '../../assets/css/sala.css';
import logoS from '../../assets/images/temp/imagen200x200.png';
import imagen_proximamente from '../../assets/images/sitio/5_TRANSMISION/fondo_transmision.jpg';

import PopUpPregunta from './PopUpPregunta';

import rede_facebook from '../../assets/images/temp/50x50.png';
import rede_ln from '../../assets/images/temp/50x50.png';
import rede_youtube from '../../assets/images/temp/50x50.png';


const { TextArea } = Input;

const app = new Firebase();

const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}
const layout = {
  wrapperCol: {
    span: 24,
  },
};

const Live = (props) =>{

  const [mis_preguntas, misPreguntas] = useState(false);
  const [en_vivo, enVivo] = useState(true);
  const [preguntas_contestadas, preguntasContestadas] = useState(false);

  const [modal_cuestionario, setModalCuestionario] = useState(false);
  const [num_pregunta, setNumPregunta] = useState(0);
  const {url, comentarios, webinar, urlfb, urltw, urlyt, urlln } = props;
  const [preguntas_popup_usuario, setPreguntasPopUpUsuario] = useState({});

  const { usuario, datos } = useContext(Auth);

  const ref_form_pregunta = useRef();

  useEffect(() => {
    if(usuario){
      console.log(usuario);
    app.obtener_objeto(webinar+'/respuestas-usuarios-en-vivo','correo',usuario.email, (data) => {
      console.log('me trajo');  
      console.log(data);
        setPreguntasPopUpUsuario(data);
    });
  }
  }, [usuario]);

  useEffect(() => {

    if( Object.keys(preguntas_popup_usuario).length !== 0){

      app.obtener_objetos(webinar+'/configuracion', true, (data) => {
        
        setNumPregunta(data[0].pregunta_popup);
  
        if(data[0].pregunta_popup === 0){
          setModalCuestionario(false);
        }else{
            if(preguntas_popup_usuario['respuesta'+data[0].pregunta_popup] === undefined || preguntas_popup_usuario['respuesta'+data[0].pregunta_popup] === null){
              setModalCuestionario(true);
            }else{
              setModalCuestionario(false);
            }
          
        }
  
      });

    }

  },[preguntas_popup_usuario]);

  const onResponseCuestionario = (valor_respuesta) => {
    setModalCuestionario(false);

    preguntas_popup_usuario['respuesta'+num_pregunta] = valor_respuesta;
    preguntas_popup_usuario['correo'] = usuario.email;
    
    app.escribir(webinar+'/respuestas-usuarios-en-vivo',preguntas_popup_usuario.key,preguntas_popup_usuario, (item) => {
      mensaje('success','Gracias por tu participación','');
    });

  }

  
    let visualizar_preguntas = comentarios.filter((pre) => {
        if(pre.status === 'aceptada'){
          return true;
        }else{
          return false;
        }
    }).map((pre)=>{
      return(
        <div className="balloon">
          <p className="texto-preguntas">
          <span className="text-pre">{pre.pregunta}</span><br />
            <strong><span className="text-nom">{pre.nombre}</span></strong><br />
            <strong><span className="text-empre">{pre.organizacion}</span></strong>
            </p>
        </div>
      );
    });

    let ver_mispreguntas = comentarios.filter((pre) => {
      if(pre.correo === datos.correo){
        return true;
      }else{
        return false;
      }
  }).map((seccion)=>{
      return(
        <div className="balloon">
        <p className="texto-preguntas">
        <span className="text-pre">{seccion.pregunta}</span><br />
          <strong><span className="text-nom">{seccion.status}</span></strong>
          </p>
      </div>
      );
    });

    let ver_contestadas = comentarios.filter((pre) => {
      if(pre.status === 'contestada'){
        return true;
      }else{
        return false;
      }
  }).map((pre)=>{
      return(
        <div className="balloon">
        <p className="texto-preguntas">
          <span className="text-pre">{pre.pregunta}</span><br />
            <strong><span className="text-nom">{pre.nombre}</span></strong><br />
            <strong><span className="text-empre">{pre.organizacion}</span></strong>
            </p>
      </div>
      );
    });

  const onFinish = values => {
  
    let pregunta = {
      organizacion: datos.organizacion,
      nombre: datos.nombre+' '+datos.ap_paterno,
      pregunta: values.pregunta,
      correo: datos.correo,
      status: 'pendiente'
    }

    app.escribir(webinar+'/preguntas-publico',undefined, pregunta, () => {
      mensaje('success','Tu pregunta ha sido enviada');
      ref_form_pregunta.current.resetFields();
    });
  };


  return(
  <Row align="middle" className="fondo_live" >
  
    <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:20, offset:2}} lg={{span:20, offset:2}} style={{textAlign:'center'}}>

      { url !== null ?
      (
      <>
      <p className="titulo_webinar color_gris_oscuro">11 DE NOVIEMBRE 2021, <strong className="color_azul">4º Foro de Socios, AMPIP 2021.</strong>
      </p>
      <div className="contenedor_video">
      <iframe className="video" src={url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen;" allowfullscreen="1"></iframe>
      </div>
      </>
      ):
      (
        <>
        <p className="titulo_webinar color_gris_oscuro">DÍA 2, <strong className="color_azul">12 DE NOVIEMBRE 2020</strong>
      </p>
        <img className="imagen_logo" src={imagen_proximamente} style={{width:'100%'}} />
        
        </>
      )  
      
      
    }

    
    </Col>
    { url !== null ?
      (<>
        <Col xs={{span:20, offset:2}} lg={0}>
          <p className="clic_ver color_azul" style={{display:'none'}}><strong>Si no puedes visualizar la conferencia da clic en
            <a href={urlyt} target="_blank" className="color_azul"> Youtube</a>
            { urlfb !== null ?
              (<>
                <a href={urlfb} target="_blank"><img src={rede_facebook} style={{width:'20px'}} /></a>
              </>)
              :
              (
                <> 
                </>
              )  
            }
            { urlln !== null ?
              (<>
                <a href={urlln} target="_blank"><img src={rede_ln} style={{width:'20px'}} /></a>
              </>)
              :
              (
                <> 
                </>
              )  
            }
            </strong>
          </p>
        </Col>
        </>)
        :
        (
          <> 
          </>
        )  
      }

<Col xs={24} lg={0}>
      <br />
    </Col>
    {/*<Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:20, offset:2}} lg={{span:6, offset:0}} className="contenedor_live_preguntas">
      <Row justify="center">
        <Col xs={{span:24}} lg={{span:0}}><br /></Col>
        <Col xs={{span:24, offset:0}} md={{span:24, offset:0}} lg={{span:23, offset:1}} className="header-preguntas back_azul">
          <Row align="middle">
            <Col span={22} offset={1}>
              <p className="pregunta-p"><strong>PREGUNTA AL CONFERENCISTA</strong>
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={{span:24, offset:0}} lg={{span:23, offset:1}}>
          <Row>
            <Col span={0} style={{display:'none'}} className={mis_preguntas === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
              <p style={{marginBottom:'0px'}} onClick={() => (misPreguntas(true), enVivo(false), preguntasContestadas(false))}>Mis preguntas</p>
            </Col>
            <Col span={12} className={en_vivo === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
              <p style={{marginBottom:'0px'}} onClick={() => (enVivo(true), misPreguntas(false), preguntasContestadas(false))}>En vivo</p>
            </Col>
            <Col span={12} className={preguntas_contestadas === true ? 'tipo_preguntas_select' : 'tipo_preguntas'}>
              <p style={{marginBottom:'0px'}} onClick={() => (enVivo(false), misPreguntas(false), preguntasContestadas(true))}>Contestadas</p>
            </Col>
            </Row>
        </Col>
        <Col xs={0}  md={{span:24, offset:0}} lg={{span:23, offset:1}} className="ver-preguntas" >
          <Row>
            <Col span={24}>
            {mis_preguntas && (
              <div>
                {ver_mispreguntas}
                </div>
            )}
            {en_vivo && (
              <div>
              {visualizar_preguntas}</div>
            )}
            {preguntas_contestadas && (
              <div>
              {ver_contestadas}</div>
            )}
            </Col>
          </Row>
        </Col>
        <Col xs={{span:24, offset:0}} md={0}  >
        <Row>
          <Col span={24} className="tipo_preguntas_select">
              <p style={{marginBottom:'0px'}}>En vivo</p>
            </Col>
            <Col span={24} className="ver-preguntas">
            
              <div>
              {visualizar_preguntas}</div>
          
            </Col>
        </Row>
        </Col>
        
        <Col xs={{span:24, offset:0}}  md={{span:24, offset:0}} lg={{span:23, offset:1}} className="contenedor_enviar back_azul">
        <Form
          {...layout}
          name="basic"
          initialValues={{
            pregunta:'',
            remember: true,
          }}
            onFinish={onFinish}
            ref={ref_form_pregunta}
          >
            <Row>
              <Col span={22} offset={1} >
                <Form.Item
                
                  name="pregunta"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor escribe una pregunta',
                    },
                  ]}
                >
                  <TextArea className="text_enviar" disabled={usuario ? false : true} />
                </Form.Item>
              </Col>
              <Col xs={{span:15, oofset:1}} md={{span:15, offset:1}}  >
                <p className="sujeto" style={{ padding:'5px 0px 0px 5px'}}>
                  
                      <span>* Todas las preguntas están sujetas a aprobación</span>
                  
                  
                  </p>
              </Col>
              <Col xs={{span:7, offset:1}}  style={{textAlign:'center', paddingTop:'5px'}}>
                <Form.Item >
                  <Button type="" htmlType="submit" className="btn-enviar color_azul" disabled={usuario ? false : true}>
                    Enviar
                  </Button>
                </Form.Item>
              </Col>
              </Row>
        </Form>
      </Col>
      
    </Row>
                </Col>*/}
    { url !== null ?
      (<>
        <Col xs={0} lg={{span:20, offset:2}}>
          <p className="clic_ver color_azul" style={{display:'none'}}><strong>Si no puedes visualizar la conferencia da clic en
            <a href={urlyt} target="_blank" className="color_azul"> Youtube</a>
            { urlfb !== null ?
              (<>
                <a href={urlfb} target="_blank"><img src={rede_facebook} style={{width:'20px'}} /></a>
              </>)
              :
              (
                <> 
                </>
              )  
            }
            { urlln !== null ?
              (<>
                <a href={urlln} target="_blank"><img src={rede_ln} style={{width:'20px'}} /></a>
              </>)
              :
              (
                <> 
                </>
              )  
            }
            </strong>
          </p>
        </Col>
         </>)
         :
         (
           <> 
           </>
         )  
       }    
    <Col xs={24} lg={0}>
      <br /><br />
    </Col>
    
   </Row>
  );
}

export default Live;