import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';


import usuario_img from '../../assets/images/registro/icono_usuario.png';
import apellido_img from '../../assets/images/registro/icono_password.png';
import img_entrar_recepcion from '../../assets/images/registro/registrate_masterclass_blanco.png';
import pago_paypal from '../../assets/images/registro/LOGO_paypal.png';

const RegistroCompleto = (props) => {

    const {correo, password, tipo_registro, nombre = ''} = props;

    useEffect(() => {
        scroller.scrollTo('header');
    },[]);

    return(
        <Row align="middle">
            <Col span={22} offset={1}><br /><br />
                <p className="textos_titulos_secciones" style={{textAlign:'center'}}>GRACIAS POR TU REGISTRO</p>
                <hr />
                <p style={{ textAlign:'center' }}>
                    <span className="texto-breve color_azul"><strong>{nombre}</strong></span>
                    <br />
                <span className="texto-breve color_gris_oscuro ">En breve recibirás un correo de confirmación a la siguiente dirección: </span><br /><br />

            <span className="correo-azul color_azul "> <strong>{correo}</strong> </span><br /> <br />
            <span className="leyenda color_azul ">Si no encuentras el mail de confirmación en tu bandeja de entrada, es posible que esté en la <br />carpeta de SPAM / NO DESEADOS.</span> <br/> <br/>
            </p>

            {tipo_registro === 'presencial' ?
                <>
                <p>
                    
                    <span className="fondo back_azul texto_titulo_instruccions">FECHAS Y HORARIOS</span><br />

                    <br />
                    <span className="texto-breve">
                        <strong>Mércoles 10 de noviembre,</strong>
                        <br />
                        Reunión del Consejo Directivo,16:30 h
                        <br />
                        Cena de Bienvenida, 19:30 h
                        </span>
                        <br /><br />
                        <span className="texto-breve">
                        <strong>Jueves 11 de noviembre,</strong>
                        <br />
                        Av. Paseo de la Reforma 500,<br />
                        Col. Juárez, Cuauhtémoc,<br />
                        C.P. 06600, CDMX
                        </span>

                        <br /><br />
                        <span className="color_azul" style={{fontSize:'30px'}}><strong>¡TE ESPERAMOS!</strong></span>
                    </p>
                </>
            :
            <>
<p>
                    <span className="fondo back_azul texto_titulo_instruccions">INSTRUCCIONES DE ACCESO</span><br />

                    </p>

<Row >
                <Col xs={{span:2, offset:0}} md={{span:1, offset:5}} lg={{span:1, offset:6}}>
                <p style={{ textAlign:'right', paddingLeft:'0%', paddingRight:'0%' }}>
                <span className="datos-azul color_azul "><strong>1. </strong></span> </p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:14, offset:0}} lg={{span:11, offset:0}} >
                <p style={{ textAlign:'left', paddingLeft:'1%', paddingRight:'0%' }}>
                <span className="datos-azul ">Ingresa a </span>
                <span className="datos-azul color_azul  ">4foro.ampip.mx</span></p>
                </Col>
                
            </Row>
            <Row>
            <Col xs={{span:2, offset:0}} md={{span:1, offset:5}} lg={{span:1, offset:6}}>
                <p style={{ textAlign:'right', paddingLeft:'0%', paddingRight:'0%' }}>
                <span className="datos-azul color_azul "><strong>2. </strong></span></p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:14, offset:0}} lg={{span:11, offset:0}}>
                <p style={{ textAlign:'left', paddingLeft:'1%', paddingRight:'0%' }}>
                <span className="datos-azul ">Copia tu <span className="datos-azul color_azul "> usuario (correo electrónico)</span> y pégalo en la casilla correspondiente.</span>
                </p>
                </Col>
            </Row>
            <Row>
            <Col xs={{span:2, offset:0}} md={{span:1, offset:5}} lg={{span:1, offset:6}}>
                <p style={{ textAlign:'right', paddingLeft:'0%', paddingRight:'0%' }}>
                <span className="datos-azul color_azul "><strong>3. </strong></span> </p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:14, offset:0}} lg={{span:11, offset:0}}>
                <p style={{ textAlign:'left', paddingLeft:'1%', paddingRight:'0%' }}>
                <span className="datos-azul ">Repite el paso anterior con tu <span className="datos-azul color_azul "> primer apellido.</span></span></p>
                </Col>
            </Row>
            <Row>
            <Col xs={{span:2, offset:0}} md={{span:1, offset:5}} lg={{span:1, offset:6}}>
                <p style={{ textAlign:'right', paddingLeft:'0%', paddingRight:'0%' }}>
                <span className="datos-azul color_azul "><strong>4. </strong></span> </p>
                </Col>
                <Col xs={{span:22, offset:0}} md={{span:14, offset:0}} lg={{span:11, offset:0}}>
                <p style={{ textAlign:'left', paddingLeft:'1%', paddingRight:'0%' }}>
                <span className="datos-azul ">Da clic en <span className="datos-azul color_azul ">ingresar</span> y disfruta del evento.</span></p>
                </Col>
            </Row>


            
<br /><br />
            <span className="fondo back_azul texto_titulo_instruccions">TUS DATOS DE ACCESO</span>
            <br /><br />

            <Row align="middle">
            <Col xs={{span:8, offset:0}} md={{span:2, offset:7}} >
                <p >
                    <img src={usuario_img} style={{width:'50px'}} />
                </p>
                </Col>
            <Col xs={{span:16, offset:0}} md={{span:7, offset:0}}>
                <p className="datos-azul " style={{textAlign:'left'}}>
                    Tu usuario (correo electrónico):
                    <br />
    <span><strong>{correo}</strong></span>
                </p>
                </Col>
            </Row>


            <Row align="middle">
            <Col xs={{span:8, offset:0}} md={{span:2, offset:7}}>
                <p >
                    <img src={apellido_img} style={{width:'50px'}} />
                </p>
                </Col>
            <Col xs={{span:16, offset:0}} md={{span:7, offset:0}}>
                <p className="datos-azul " style={{textAlign:'left'}} >
                    Tu primer apellido:
                    <br />
    <span><strong>{password}</strong></span>
                </p>
                </Col>
            </Row>



            <br />
            <p className="nota color_gris_oscuro ">Nota: La transmisión se habilitará 30 minutos antes del evento.</p><br /> <br />
            </>

            }

            </Col>
            </Row>
    );
}

export default RegistroCompleto;