import React, { useState, useContext, useEffect, useRef } from "react";
import RegistroCompleto from './RegistroCompleto';
import '../../assets/css/login.css';
import {Auth} from '../../utils/AuthContext';

import banner from '../../assets/images/evento/1_REGISTRO/banner_4foro_ampip2021.jpg';

import logo_banner_ergistro from '../../assets/images/evento/1_REGISTRO/logo_4foroampip2021.png';

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Modal,
  Spin,
  InputNumber
} from 'antd';
import  Firebase  from "../../utils/firebaseConfig";
import  { mail_registro_confirmacion, existe_empresa } from "../../utils/ConexionServidor";

import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";

import img_crear from '../../assets/images/evento/1_REGISTRO/btn_registro.png';
import img_ayuda from '../../assets/images/evento/1_REGISTRO/btn_problemasregistro.jpg';
import wts from '../../assets/images/registro/btn_problemasregistro.png';
import boton_ya_cuenta from '../../assets/images/temp/image200x100.png';

import LoginExiste from '../registro/LoginExiste';

const openNotificationWithIcon = (type,titulo, texto) => {
  notification[type]({
    message: titulo,
    description: texto,
    duration: 10,
  });
};

const nom_evento = "ampip-convencion-2021";

const { Option } = Select;

let correo_registrado = '', password_registrado = '', perfil_registrado = '';

const app = new Firebase();

const success = () => {
  openNotificationWithIcon('success','Bienvenido','');
};

const Registro = ({ setsignup, history, tipo_registro }) => {
    
    const [otro_medio, setOtroMedio] = useState(false);
    const [confirmacion, setConfirmacion] = useState(false);
    const [cargando_regsitro, setCargandoRegistro] = useState(false);
    const { usuario, datos } = useContext(Auth);
    const [mostrar_login, setMostrarLogin] = useState(false);
    const [reutilizar_datos, setReutilizarDatos] = useState(false);
    const [tipo_perfl, setTipoPerfil] = useState('');
    const [nombre_completo, setNombreCompleto] = useState('');
    const [empresa_repetida, setEmpresaRepetida] = useState(false);
    const [perfil_elegido, setPerfilElegido] = useState(false);

    const [form] = Form.useForm();

    const ref_form = React.createRef();
    const ref_organizacion = React.createRef();
    const ref_recaptcha = React.createRef();
    

    const elegirMedio = value => {
      console.log(value);

      if(value === 'Otro'){
        setOtroMedio(true);
      }else{
        setOtroMedio(false);
      }

    }

    useEffect(()=>{
      if(usuario){
        app.app.auth().signOut().then(function() {
        
        }).catch(function(error) {
          // An error happened.
        });
      }
    },[]);

    useEffect(()=>{
      setEmpresaRepetida(false);
    },[tipo_perfl]);

    useEffect(() => {
      console.log('se reutiliza: ', reutilizar_datos);
        if(reutilizar_datos){
          
          if(datos){
            console.log('existen los datos');
            
      datos.nombre_curso = nom_evento;
      datos.apellidos = datos.ap_paterno+' '+datos.ap_materno;

            mail_registro_confirmacion(datos).then(({data,status}) => {
              switch(status){
                case 0:
                  
                  //delete values.aceptar_terminos;
                  app.escribir(nom_evento+'/usuarios',undefined,datos, () => {
                    
                  });
                  
                  openNotificationWithIcon('success','',data);
                  setConfirmacion(true);
                break;
                default:
                  openNotificationWithIcon('warning','',data);
                  setConfirmacion(false);
                  break;
              }
              //setCargandoRegistro(false);
              setMostrarLogin(false);
            });
            setReutilizarDatos(false);

          }else{
            console.log('no existen los dtos');
          }
        }
    }, [datos, reutilizar_datos]);

    const registrarLoginExiste = (medio)=> {
      setReutilizarDatos(true);
    }

    const onFinish = async values => {

      if(empresa_repetida){
        openNotificationWithIcon('warning','YA EXISTE UN REGISTRO PARA ESTA EMPRESA','Le recordamos que el evento presencial esta restringido a un registro por empresa, por lo que le sugerimos hacer el resto de sus inscipciones en el apartado “REGISTRO A EVENTO VIRTUAL”');
        return;
      }

      setCargandoRegistro(true);

      let recaptcha = 'sin-recaptcha'; //ref_recaptcha.current.getValue();

      values.correo = values.correo.toLowerCase();

        const { correo } = values;

        setNombreCompleto(values.nombre+' '+values.ap_paterno);

        let contra = values.password;

        correo_registrado = correo;

        values.nombre_curso = nom_evento;

              values.apellidos = values.ap_paterno;
              
              values['g-recaptcha-response'] = recaptcha;
              

              const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
              values.pass = values.ap_paterno.split('').map( letra => acentos[letra] || letra).join('').toString().toLowerCase();	

              password_registrado = values.pass;
              perfil_registrado = values.perfil;

              values.tipo_entrada = tipo_registro;

              mail_registro_confirmacion(values).then(({data,status}) => {
                switch(status){
                  case 0:
                    console.log(values);
                    delete values.medios;
                    delete values.pass;
                    delete values.aceptar_terminos;
                    //delete values.aceptar_networking;

                    delete values.otro_procedencia;
                    
                    
                    openNotificationWithIcon('success','',data);
                    setConfirmacion(true);
                  break;
                  default:
                    openNotificationWithIcon('warning','',data);
                    setConfirmacion(false);
                    break;
                }
                setCargandoRegistro(false);
                
              });
      };

      const revisarEmpresa = (empresa) => {
        if(tipo_registro === 'presencial'){
          existe_empresa({empresa:empresa}).then(({data}) => {
            if(data === 1){
              openNotificationWithIcon('warning','YA EXISTE UN REGISTRO PARA ESTA EMPRESA','Le recordamos que el evento presencial esta restringido a un registro por empresa, por lo que le sugerimos hacer el resto de sus inscipciones en el apartado “REGISTRO A EVENTO VIRTUAL”');
              setEmpresaRepetida(true);
            }else{
              setEmpresaRepetida(false);
            }
          })
        }
      }

      const revisarPErfil = (perfil) => {
        if(perfil !== ''){
          setPerfilElegido(true);
        }
      }


    return (
      <>
      <Spin tip="Cargando..." spinning={cargando_regsitro}>
      
        <Row>
        <Col span={24}>
          <Row style={{position:'relative'}}>
          <Col span={24} style={{position:'relative'}}>
                <img src={banner} style={{ width:'100%' }} />
            </Col>
            <Col span={16} offset={4} style={{position:'absolute'}}>
                <img src={logo_banner_ergistro} style={{ width:'100%' }} />
            </Col>
          </Row>
        </Col>

          {confirmacion ? 
          (
            <Col span={24}>
            <RegistroCompleto correo={correo_registrado} password={password_registrado} tipo_registro={tipo_registro} nombre={nombre_completo} />
            </Col>
          )
        :
        (
          <>
<Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>

  <br />
  <p className="texto_inicio_registro color_gris_oscuro">
    {tipo_registro === 'presencial' ?
    <>
    <span className="texto_inicio_registro_hash color_azul_claro" ><strong>MÉXICO, A DOS AÑOS DEL COVID-19</strong></span><br />
      <span className="texto_inicio_registro_hash color_azul" ><strong>Hotel Four Seasons</strong></span><br />
      <span className="color_gris_oscuro">CDMX, México.</span><br />
      <span className="color_azul_claro"><strong>10 y 11 de noviembre 2021</strong></span>
      </>
    :
    <>
    <span className="texto_inicio_registro_hash color_azul_claro" ><strong>MÉXICO, A DOS AÑOS DEL COVID-19</strong></span><br />
      <span className="texto_inicio_registro_hash color_azul" ><strong>Transmisión en tiempo real</strong></span><br />
      <span className="color_gris_oscuro"><strong>A través del </strong><br />sitio del evento</span><br />
      <span className="color_gris_oscuro"><strong>Sección: </strong><br />Transmisión de evento</span><br />
      <span className="color_azul_claro"><strong>10 y 11 de noviembre 2021</strong></span>
    </>
    }
    

  </p>
            <Form className="login-form"
        style={{backgroundColor:'rgb(255,255,255)'}}
        ref = {ref_form}
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
          organizacion:''
        }}
        onFinish={onFinish} 
        >

          <Row style={{marginLeft:'20px',marginRight:'20px'}}>
            <Col span={24}>
            <p className="textos_titulos_secciones color_azul">
              <br />
              {tipo_registro === 'presencial' ? <strong>REGISTRO A EVENTO PRESENCIAL</strong>
              :
              <strong>REGISTRO A EVENTO VIRTUAL</strong>}
                <hr />
                </p>
            </Col>
           
            <Col xs={{span:24, offset:0}} md={{span:11, offset:0}} >
            <Form.Item className="nom-label"
        name="nombre"
        label={<span className="color_gris_oscuro ">Nombre(s):</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}} className="">Ingresa tu nombre</span>,
          },
        ]}
        
      >
        <Input />
      </Form.Item>
            </Col>
            <Col xs={{span:24, offset:0}} md={{span:11, offset:2}} >
            <Form.Item className="nom-label"
        name="ap_paterno"
        label={<span className="color_gris_oscuro ">Apellido Paterno:</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}} className="">Ingresa tu apellido paterno</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>

             <Col span={24}>
            <Form.Item className="nom-label"
        name="correo"
        label={<span className="color_gris_oscuro ">Correo electrónico corporativo:</span>}
        rules={[
          
          {
            required: true,
            message: <span style={{color:'red'}} className="">Ingresa un correo válido</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>

                <Col span={24}>
            <Form.Item className="nom-label"
        name="perfil"
        label={<span className="color_gris_oscuro ">Perfil:</span>}
        rules={[
          {
            required:true,
            message: <span style={{color:'red'}} className="">Selecciona un perfil</span>
          },
        ]}
      >
        <Select
         
          style={{width:'100%', textAlign:'left'}}  onChange={(e) => {setTipoPerfil(e); console.log(ref_form.current.resetFields(['organizacion']));}} 
          
        >
          <Option value="Socio">Socio</Option>
          <Option value="Patrocinador">Patrocinador</Option>
          <Option value="Aliado">Aliado</Option>
          {tipo_registro === 'presencial' ?
          <Option value="Conferencistas">Conferencistas</Option>
          :
          <Option value="Invitado">Invitado</Option>
          }
          <Option value="Staff">Staff</Option>
        </Select>
      </Form.Item>
            </Col>
            {tipo_perfl !== '' &&

            <Col span={24}>
            <Form.Item className="nom-label"
        name="organizacion"
        label={<span className="color_gris_oscuro">Organización:</span>}
        rules={[
          {
            required:true,
            message: <span style={{color:'red'}}>Selecciona una organización</span>
          },
        ]}
        initialValue=''
        
      >

        { (tipo_perfl === 'Socio' || tipo_perfl === 'Patrocinador' ) ?

<Select ref={ref_organizacion}
         
         style={{width:'100%', textAlign:'left'}}
         
         onChange={revisarEmpresa}
         
       >


        { tipo_perfl === 'Socio' ?
          <>
        <Option value="Advance">Advance </Option>
<Option value="Alveo">Alveo</Option>
<Option value="American Industries">American Industries</Option>
<Option value="A.I.  San Jorge">A.I.  San Jorge </Option>
<Option value="Artha">Artha</Option>
<Option value="ATISA">ATISA </Option>
<Option value="BTS">BTS </Option>
<Option value="Cactus Valley">Cactus Valley </Option>
<Option value="CBP Mérida">CBP Mérida</Option>
<Option value="CIESA">CIESA</Option>
<Option value="COFOIN">COFOIN</Option>
<Option value="Corporativo MS">Corporativo MS</Option>
<Option value="CPA">CPA</Option>
<Option value="Davisa">Davisa</Option>
<Option value="El Florido">El Florido </Option>
<Option value="Exeter">Exeter </Option>
<Option value="Fibras Macquire">Fibras Macquire</Option>
<Option value="FIBRA MTY">FIBRA MTY</Option>
<Option value="Fibra UNO">Fibra UNO</Option>
<Option value="FIDECIX">FIDECIX</Option>
<Option value="FIDEPAR">FIDEPAR</Option>
<Option value="FINSA">FINSA</Option>
<Option value="GP Desarrollos">GP Desarrollos</Option>
<Option value="Grupo Marabis">Grupo Marabis</Option>
<Option value="Grupo Nelson">Grupo Nelson</Option>
<Option value="Hines">Hines</Option>
<Option value="IAMSA">IAMSA</Option>
<Option value="Intermex">Intermex</Option>
<Option value="Interpuerto MTY">Interpuerto MTY</Option>
<Option value="Lintel">Lintel</Option>
<Option value="Logistik">Logistik</Option>
<Option value="Nicoya">Nicoya</Option>
<Option value="O'Donnell">O'Donnell</Option>
<Option value="Oradel">Oradel</Option>
<Option value="P. I. Amistad">P. I. Amistad</Option>
<Option value="P.I.  AeroTech">P.I.  AeroTech</Option>
<Option value="P.I.  Atitalaquia">P.I.  Atitalaquia</Option>
<Option value="P.I.  Yucatán ">P.I.  Yucatán </Option>
<Option value="P.I. Bermúdez">P.I. Bermúdez</Option>
<Option value="P.I. Calafia">P.I. Calafia</Option>
<Option value="P.I. Millennium">P.I. Millennium</Option>
<Option value="P.I. Opción">P.I. Opción</Option>
<Option value="P.I. PyME">P.I. PyME</Option>
<Option value="P.I.Marán">P.I.Marán</Option>
<Option value="P.L. San Julián">P.L. San Julián </Option>
<Option value="PGIM">PGIM </Option>
<Option value="PIMSA">PIMSA</Option>
<Option value="Prologis">Prologis</Option>
<Option value="Prosperity Industrial">Prosperity Industrial</Option>
<Option value="Puerta del Norte">Puerta del Norte</Option>
<Option value="Roca Desarrollos">Roca Desarrollos</Option>
<Option value="SEDECO, Durango">SEDECO, Durango</Option>
<Option value="SEDECO, Puebla">SEDECO, Puebla</Option>
<Option value="SEZAC,  Zacatecas">SEZAC,  Zacatecas</Option>
<Option value="Stiva">Stiva</Option>
<Option value="Terrafina">Terrafina</Option>
<Option value="TerraRegia">TerraRegia</Option>
<Option value="Tetakawi">Tetakawi</Option>
<Option value="VESTA">VESTA</Option>
<Option value="VYNMSA">VYNMSA</Option>
<Option value="Walton Street">Walton Street </Option>
<Option value="WTC Industrial">WTC Industrial</Option>
       </>
        :
        tipo_perfl === 'Patrocinador' ?
        <>
          <Option value="AT&T">AT&T</Option>
<Option value="Bancomext">Bancomext</Option>
<Option value="Butler">Butler</Option>
<Option value="CBRE">CBRE</Option>
<Option value="CFEnergía">CFEnergía</Option>
<Option value="Cien Consultores">Cien Consultores</Option>
<Option value="Colliers">Colliers </Option>
<Option value="Copachisa">Copachisa</Option>
<Option value="Credit Suisse">Credit Suisse</Option>
<Option value="Crocsa">Crocsa </Option>
<Option value="Deltack">Deltack</Option>
<Option value="Empire Roofing">Empire Roofing</Option>
<Option value="Énestas">Énestas</Option>
<Option value="ENGIE">ENGIE </Option>
<Option value="Estructuras Solana">Estructuras Solana </Option>
<Option value="GE Silicones">GE Silicones</Option>
<Option value="Grupo Dragón">Grupo Dragón</Option>
<Option value="Hermosillo">Hermosillo</Option>
<Option value="HITACHI ABB">HITACHI ABB</Option>
<Option value="Iberdrola">Iberdrola</Option>
<Option value="IGSA">IGSA</Option>
<Option value="Isocindu">Isocindu</Option>
<Option value="JLL">JLL</Option>
<Option value="Kansas City">Kansas City </Option>
<Option value="Owens Corning">Owens Corning</Option>
<Option value="Top Management">Top Management</Option>
<Option value="Transtelco">Transtelco</Option>
<Option value="Ubicuity">Ubicuity </Option>
        </>
        :
<>
          
          <Option value="México Now">México Now</Option>
<Option value="Grupo Si">Grupo Si</Option>
<Option value="AES">AES</Option>
        </>
        }
 </Select>
 :
 <Input style={{width:'100%'}} />
      }
        
      </Form.Item>
            </Col>

          }
            <Col span={24}>
            <Form.Item className="nom-label"
        name="cargo"
        label={<span className="color_gris_oscuro">Cargo:</span>}
        rules={[
          {
            required: true,
            message: <span style={{color:'red'}}>Ingresa tu cargo</span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            
            <Col span={24}>
            <Form.Item className="nom-label"
        name="medio"
        label={<span className="color_gris_oscuro">¿Por qué medio te enteraste del evento?</span>}
        rules={[
          {
            required: true,
            message: <span style={{color:'red'}}></span>,
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>

        {tipo_registro === 'presencial' &&
         <Col span={24} style={{textAlign:'left'}}>
         <Form.Item className="nom-label"
     name="asistir_miercoles"
     valuePropName="checked"
     rules={[
       {
         required:false
       }
     ]}
   >
     <Checkbox className="nom-label">
     <span style={{fontSize:'16px'}} className="">¿Asistirás a la cena de socios del miércoles 10 de noviembre del 2021?</span>
     </Checkbox>
   </Form.Item>
         </Col>
        }
           
            <Col span={24} style={{textAlign:'left'}}>
            <Form.Item className="nom-label"
        name="aceptar_networking"
        valuePropName="checked"
        rules={[
          {
            required:false
          }
        ]}
      >
        <Checkbox className="nom-label">
        <span style={{fontSize:'16px'}} className="">Acepto que mis datos sean utilizados para fines estadísticos.</span>
        </Checkbox>
      </Form.Item>
            </Col>

            <Col span={24} style={{textAlign:'left'}}>
            <Form.Item className="nom-label"
        name="aceptar_terminos"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(<span style={{color:'red'}}>He leído y acepto los términos y condiciones.</span>),
          },
        ]}
      >
        <Checkbox className="nom-label" >
          <span className="" style={{fontSize:'16px'}}>Acepto el <span onClick={ () => { window.open('https://ampip.org.mx/es/aviso-de-privacidad/','Aviso de privacidad',"top=100,left=500,width=600,height=600");}}>
                      <strong>Aviso de Privacidad</strong>
                    </span> 
                    </span>

          
        </Checkbox>
      </Form.Item>
            </Col>

            <Col span={24}>
              <br /><br />
            </Col>

            <Col xs={{span:24, offset:0}} md={{span:5, offset:0}}>
              <p className="color_azul " style={{textAlign:'left', fontWeight:'bold'}}>
              Grabación y videografía.
              </p>
            </Col>
            <Col xs={{span:24, offset:0}} md={{span:19, offset:0}}>
              <p style={{textAlign:'justify'}} className="">
              Los asistentes al 4º Foro de Socios Ampip 2020 pueden ser capturados por audio y/o video, previo consentimiento para aparecer en grabaciones de conferencias y materiales promocionales. Si no deseas aparecer en las grabaciones de la conferencia, envía un un correo electrónico a <a href="mailto:ctoribio@ampip.org.mx" className="color_gris" style={{textDecoration:'none'}}>ctoribio@ampip.org.mx</a>
              </p>
            </Col>

            <Col xs={{span:24, offset:0}} md={{span:5, offset:0}}>
              <p className="color_azul " style={{textAlign:'left', fontWeight:'bold'}}>
              Código de conducta.
              </p>
            </Col>
            <Col xs={{span:24, offset:0}} md={{span:19, offset:0}}>
              <p style={{textAlign:'justify'}} className="">
              Toda comunicación debe ser apropiada para una audiencia profesional. El acoso a los participantes por medio del sitio del evento está prohibido y puede resultar en la expulsión del Foro.
              </p>
            </Col>
            
            <Col span={24}>
            <Form.Item>
              <br />
              <img style={{ width:'200px', padding:'0px', cursor:'pointer' }} src={img_crear} alt="Entrar" onClick={() => { ref_form.current.submit(); }} />
                
                </Form.Item>
            </Col>
          </Row>

          <div id="recaptcha-container">
          </div>
          
        </Form>
            </Col>
            <Col xs={{offset:2,span:20}} md={{offset:6, span:12}}>
              <a href="https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP." target="_blank"><img style={{ width:'100%' }} src={img_ayuda} alt="Entrar" /></a>
                
            </Col>
            <Col span={24}>
              <br /><br />
            </Col>
            </>
        )
        }
        </Row>

        </Spin>

        <Modal
          title="INICIA SESIÓN"
          visible={mostrar_login}
          onOk={() => {setMostrarLogin(false)}}
          onCancel={() => {setMostrarLogin(false)}}
          footer={null}
        >
          <LoginExiste onResponse={() => {registrarLoginExiste() } } onClick={success} />
        </Modal>

        </>
        
    );
};

export default withRouter(Registro);
