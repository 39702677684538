import React, { useEffect, useContext ,useState} from "react";
import { Row, Col, Modal, Spin, message , Button, notification} from "antd";
import { Auth } from "../../utils/AuthContext";
import { Redirect, withRouter } from "react-router";
import Firebase from '../../utils/firebaseConfig';
import Live from '../conferencias/Live';
import {Preguntas} from '../conferencias/Preguntas'; 
import {Estadisticas} from '../conferencias/Estadisticas';

import logo_convencion from '../../assets/images/sitio/5_TRANSMISION/logo-1_citiusAG.png';

import Header from '../master/Header';
import Footer from '../master/Footer';

const nombre_evento = 'ampip-convencion';

const app = new Firebase();

let key = '';
const success = () => {
    mensaje('success','Bienvenido','');
  };

  const mensaje = (tipo,titulo, texto) =>{

    notification[tipo]({
      message: titulo,
      description: texto,
    });
  
  }

const Demo =({history}) =>{

    const { usuario, datos } = useContext(Auth);
    const [preguntas_ponente, setPreguntasPonente] = useState([]);
    const [etapa, setEtapa] = useState(0);
    const [error_correo, setErrorCorreo] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const [estadisticas, setEstadisticas] = useState([]);
    const [mostrar_login, setMostrarLogin] = useState(false);
    const [sin_login, setSinLogin] = useState(false);

    const siguienteEtapa = (usuario_respuestas) => {
        console.log(usuario_respuestas);
        key = usuario_respuestas.key;
        switch (etapa) {
            case 0:
                let existe_respuestas = usuario_respuestas.respuestas;
                    app.obtener_objetos(nombre_evento+'/preguntas', false, (datos) => {
                        console.log('las preguntas');
                        console.log(datos);
                        setPreguntas(datos);
                        app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {
                            setEstadisticas(datos);
                            setCargando(false);
                            if (typeof existe_respuestas !== 'undefined') {
                                setEtapa(2);
                            } else {
                                setEtapa(1);
                            }
                        });
                    })
                
                break;
            case 1:

                app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                    setEstadisticas(datos);
                    setCargando(false);
                    setEtapa(2);
                });
                break;
            default:
            break;
        }
    }

    const guardarRespuestas = (respuestas) => {

        console.log(respuestas);

        const actualizar_usuario = {
            correo: usuario.email,
            respondio: true,
            respuestas: respuestas
        }
        setCargando(true);
        app.escribir(nombre_evento + '/usuarios-encuesta', key, actualizar_usuario, siguienteEtapa);

    }

    useEffect(() => {
        
        if (!usuario) {
            mensaje('warning','Advertencia','Para entrar a esta sección necesitas haber iniciado sesión.');
            //setSinLogin(true);
            console.log(usuario);
        }
        
    }, [usuario]);

    return (

        sin_login ? 

            <>
            <Redirect to="/" />
            </>
        :

        <>
        
        <div className="cuerpo">
            <Row align="">
                <Col xs={{span:20, offset:1}} md={{span:8, offset:1}} style={{textAlign:'left'}}>
                    <br />
                </Col>
            <Col span={24}>
                <Live url="https://www.youtube.com/embed/qb83DbpLs34?rel=0;autoplay=1;" urlyt="https://www.youtube.com/watch?v=qb83DbpLs34&feature=youtu.be" urlfb={null} urlln={null} webinar={nombre_evento} comentarios={preguntas_ponente} />
            </Col>
            <Col span={24}>
                <br/>
            </Col>
            </Row>
        </div>

        

                </>

        


        
    );

}

export default Demo;

