import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Radio, Button } from 'antd';
import Firebase from '../../utils/firebaseConfig';
import '../../assets/css/registro.css';

const nombre_evento = 'ampip-convencion';
const app = new Firebase();

const PopUpPregunta = (props) => {

    const { num_pregunta, onResponse } = props;
    const [pregunta, setPregunta] = useState('');
    const [respuestas, setRespuestas] = useState([]);
    const [cambio, setCambio] = useState(true);

    useEffect(() => {
        app.obtener_objetos(nombre_evento+'/preguntas-vivo',false,(items) => {

          console.log('el array');
          console.log(items);

            let preg = items.filter(item => {
                if(item.num_pregunta === num_pregunta){
                    return true;
                }else{
                    return false;
                }
            });

            if(preg.length === 1){
                setPregunta(preg[0].pregunta);
                setRespuestas(Object.values(preg[0].respuestas));
            }
        });

        setCambio(true);
    },[num_pregunta]);


    const onFinish = values => {
        onResponse(values["radio-group"]);
        setCambio(false);
    };

    return(
        <Row>
            <Col span={20} offset={2}>
    <p className="color_azul"><strong>{pregunta}</strong></p>
            <Form
      name="validate_other"
      onFinish={onFinish}
      initialValues={{
      }}
    >

            <Form.Item name="radio-group" label="" 
            rules={[
          {
            required: true,
            message: <span style={{color:'red'}}>Elige una opción</span>,
          }
        ]}>

        {cambio ? 

<Radio.Group style={{textAlign:'left'}}>
{respuestas.map(respuesta => {
    return(
        <>
            <Radio value={respuesta.respuesta}><span className="selector_radio_opcion">{respuesta.respuesta}</span></Radio>
            <br />
        </>
    );
})}
</Radio.Group>

: <>
<Radio.Group style={{textAlign:'left'}}>
            {respuestas.map(respuesta => {
                return(
                    <>
                        <Radio value={respuesta.respuesta}><span className="selector_radio_opcion">{respuesta.respuesta}</span></Radio>
                        <br />
                    </>
                );
            })}
        </Radio.Group></>
        
      }

        
      </Form.Item>

      <Form.Item
        style={{textAlign:'center'}}
      >
        <Button className="boton_sin_login" htmlType="submit">
          Votar
        </Button>
      </Form.Item>
    </Form>

            </Col>
        </Row>
    );
}

export default PopUpPregunta;
