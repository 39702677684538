import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import ICalendarLink from "react-icalendar-link";
import { Element } from 'react-scroll';


import Agenda from './Agenda';
import Ponentes from './Ponentes';

import '../../assets/css/webinars.css';

import entrar from '../../assets/images/sitio/1_INICIO/btn_registro.png';
import banner_principal from '../../assets/images/sitio/1_INICIO/bannerprincipal.jpg';
import agregar_calendar from '../../assets/images/sitio/1_INICIO/btn_anadircalendario.png';

import banner from '../../assets/images/evento/1_REGISTRO/banner_4foro_ampip2021.jpg';

import logo_banner_ergistro from '../../assets/images/evento/1_REGISTRO/logo_4foroampip2021.png';
import img_salud from '../../assets/images/evento/2_HOME/iconos_sanitarios.png';

import video from '../../assets/images/temp/500x400.png';

import '../../assets/css/conferencia.css';
import Patrocinadores from '../patrocinadores/Patrocindaores';

const event = {
  title: "Convención Virtual AMPIP 2020",
  description: "Nuevos retos ante nuevas realidades",
  startTime: "2020-11-11T09:30:00-06:00",
  endTime: "2020-11-12T13:00:00-06:00",
  location: ""
};

const Webinars = () => {

    return(
      <>
      <Row>
        <Col span={24}>
          <Row style={{position:'relative'}}>
          <Col span={24} style={{position:'relative'}}>
                <img src={banner} style={{ width:'100%' }} />
            </Col>
            <Col span={16} offset={4} style={{position:'absolute'}}>
                <img src={logo_banner_ergistro} style={{ width:'100%' }} />
            </Col>
          </Row>
          </Col>
          
          </Row>
      
      <Row className="contenedor_conferencias">
        <Element name="#registro"/>
      <Col span={24} className="contendor_hastag">
      <Row align="middle">
        <Col xs={{span:20, offset:2}} lg={{span:20, offset:2}} className="contenedor_experiencia">
        <p className="texto_experiencia_hashtag color_azul"><strong>“MÉXICO, A DOS AÑOS DEL COVID-19”</strong></p>
        <p className="parrafo_experiecia">
        
        </p>
        </Col>
      </Row>
      </Col>
      <Col span={24} className="contenedor_eventos">
        <Row>
          <Col xs={{offset:4, span:16}} md={{offset:4, span:6}}>
            <Link to="/registro/presencial"><div className="div_home_blanco"><span className="color_azul_claro texto_home_eventos"><strong>REGISTRO<br />EVENTO PRESENCIAL</strong></span></div></Link>
            <br />
            <p className="texto_home_eventos">
              <span className="color_azul"><strong>Sede: Hotel Four Seasons</strong></span><br />
              <span className="color_gris_oscuro">
              CDMX, México<br />
                <strong>Aforo máximo: 100 personas</strong>
              </span><br />
              <span className="color_azul_claro"><strong>10 y 11 de noviembre 2021</strong></span>
            </p>
          </Col>
          <Col xs={{offset:4, span:16}} md={{offset:4, span:6}}>
            <Link to="/registro/virtual"><div className="div_home_azul"><span className="color_blanco texto_home_eventos"><strong>REGISTRO<br />EVENTO VIRTUAL</strong></span></div></Link>
            <br />
            <p className="texto_home_eventos">
              <span className="color_azul"><strong>Transmisión vía streaming</strong><br /> en tiempo real</span><br />
              <span className="color_gris_oscuro">
              a través de la <strong>plataforma del evento (landing page)</strong><br />
              </span>
              <span className="color_azul_claro"><strong>10 y 11 de noviembre 2021</strong></span>
            </p>
          </Col>
        </Row>
      </Col>
      <Col span={20} offset={2}>
        <Row align="middle">
          <Col span={1}>
          <p style={{textAlign:'left'}}><hr className="hr_titulos"/></p>
          </Col>
          <Col span={23}>
          <p className="color_azul textos_titulos_secciones" style={{textAlign:'left'}}>ACERCA DE</p>
          </Col>
        </Row>
        <Col span={24}>
        <p className="textos_home">
        Transitamos por un periodo desafiante, que trajo consigo una nueva forma de comunicarnos y relacionarnos. A dos años de iniciada la contingencia sanitaria, ¡por fin podremos encontrarnos nuevamente!, aunque dentro de los límites sanitarios obligados.
        <br /><br />
        Hoy más que nunca es relevante conocer el estado de la economía y del mercado inmobiliario industrial, así como reflexionar sobre los retos que enfrentamos en la nueva normalidad post COVID-19. En este <strong><span className="color_azul">4º Foro 2021 de la AMPIP,</span></strong> encontrarás el espacio idóneo para hacerlo.
        </p><br />
        <p className="textos_home_2 color_azul_claro"><strong>#SomosAMPIP</strong></p>
        </Col>
      </Col>
      <Col id="agenda" span={24}>
      <Element name="#agenda"></Element>
        <Agenda />
        <br /><br />
      </Col>
      <Col span={24} className="back_azul">
        
        <Row align="middle">
          <Col xs={{span:20, offset:2}} md={{span:11, offset:2}}>
          <Row align="middle">
            <Col xs={2} md={2}>
            <p style={{textAlign:'left'}}><hr className="hr_titulos"/></p>
            </Col>
            <Col xs={20} md={22}>
              <p className="textos_titulos_secciones" style={{textAlign:'left', color:'#ffffff'}}>MEDIDAS SANITARIAS</p>
            </Col>
          </Row>
          </Col>
          <Col xs={{span:20, offset:2}} md={{span:10, offset:0}}>
            <img src={img_salud} style={{width:'100%'}} />
          </Col>
        </Row>
      </Col>

      <Col span={20} offset={2}><br />
          <p className="textos_home color_azul"><strong>Agradeceremos su colaboración para cumplir con las siguientes indicaciones, a efecto de mitigar posibles contagios.</strong></p><br />
          <ul className="lista_sanidad textos_home color_gris">
            <li><span className="color_azul"><strong>Toma de temperatura</strong></span><br />Al llegar a las instalaciones, con el fin de detectar cualquier caso sospechoso.</li>
            <li><span className="color_azul"><strong>Uso de cubrebocas</strong></span><br />Cubre de manera correcta, nariz, boca y barbilla; úsalo en todo el evento. Puedes retirarlo cuando consumas alimentos y bebidas.</li>
            <li><span className="color_azul"><strong>Sana Distancia</strong></span><br />Respeta los flujos de tráfico para permitir una distancia adecuada, procura la separación de 1.5 metros con respecto a otros asistentes.</li>
            <li><span className="color_azul"><strong>Lavado de manos</strong></span><br />Lava tus manos con abundante agua y jabón, o en sustitución aplica gel antibacterial.</li>
            <li><span className="color_azul"><strong>Síntomas</strong></span><br />Si presentas síntomas de COVID-19, durante el evento o posterior al mismo, aíslate y notifícalo oportunamente a la AMPIP.</li>
          </ul>
          <br />
          </Col>
          <Col span={24} className="back_azul_claro"><br />
            <p className="textos_home color_blanco" style={{textAlign:'center'}}>Sumemos esfuerzos para hacer del<br /><strong>4º Foro de Socios AMPIP 2021,</strong> un espacio libre de contagios.</p>
            <br />
          </Col>
          <Col span={24} className=""><br />
            <p className="textos_home color_gris" style={{textAlign:'center'}}>Deseamos que sea un evento provechoso para todos los asistentes<br /> y que disfrutemos con seguridad de esta nueva normalidad.</p>
            <br />
            <p className="textos_home_2 color_azul_claro" style={{textAlign:'center'}}><strong>Juntos somos más fuertes</strong></p>
          </Col>
          <Col span={24}>
          <br />
        </Col>
      <Col span={24}>
      <Element name="#conferencistas"></Element>
        <Ponentes />
      </Col>
      <Col span={24}>
      <Element name="#patrocinadores"></Element>
        <Patrocinadores />
      </Col>
      
        <Col span={24}>
          <br /><br /><br />
        </Col>
      
      </Row>
        
        </>
    );
}

export default Webinars;