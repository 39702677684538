import React, { useContext, useState, useEffect } from 'react';
import {Link, useLocation } from 'react-router-dom';
import { Row, Col, Modal, notification } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import '../../assets/css/evento.css';

import ima from '../../assets/images/temp/50x50.png';
import logo_ampip from '../../assets/images/sitio/1_INICIO/LOGO_AMPIP__header.png';
import ima2 from '../../assets/images/temp/50x50.png';
import {Auth} from '../../utils/AuthContext';
import Login from '../registro/Login';


const secciones = [
  {nombre:'INICIO', url:'/'},
  {nombre:'AGENDA', url:'/#agenda'},
  {nombre:'CONFERENCISTAS', url:'/conferencistas'},
  //{nombre:'NETWORKING', url:'/networking'},
  {nombre:'PATROCINADORES', url:'/patrocinadores'},
  //{nombre:'TRANSMISIÓN', url:'/transmision'},
];

const success = () => {
  mensaje('success','Bienvenido','');
};



const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}

const Header = ({history}) => {

  const { usuario } = useContext(Auth);
  const [modal_entrar, setModalEntrar] = useState(false);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [mostrar_submenu, setMostrarSubmenu] = useState(false);

  useEffect(() => {
    if (usuario) {
      setMostrarLogin(false);
    }else{
        
        setMostrarLogin(false);//cambiar a true
    }
  },[usuario]);

  let location = useLocation();

    console.log(location.pathname+''+location.hash);

    let visualizar_secciones = secciones.map((seccion)=>{

      if(seccion.url === '/transmision'){
        if(!usuario){
          return(
            <>

          </>
          );
        }
      }

      return(
        <Link className="titles" to={seccion.url}>
          <span className={location.pathname+''+location.hash === seccion.url || location.pathname === seccion.url ? "seccion_sel Century Gothic Regular" : "seccion Century Gothic Regular"} >
            {seccion.nombre}
          </span>
        </Link>
      );
    });

  return(
    <>

    
    <Row justify="space-around" align="middle" style={{paddingTop:'10px', paddingBottom:'10px'}}>

      <Col xs={{span:4, offset:0}} md={{span:3, offset:1}} style={{ padding: '0px' }}>
      
      <img alt="logo" style={{width:'100%'}} src={logo_ampip}/>
      

      </Col>

      {location.pathname !== '/visualizar-preguntas' && 

<>
      <Col xs={{span:0, offset:0}} lg={{span:17, offset:0}}>
        
        {visualizar_secciones}

        {!usuario &&
          <Link className="titles" onClick={() => {setMostrarLogin(true)}} to="" style={{display:'none'}}>
          <span className="seccion Century Gothic Regular" >
            TRANSMISIÓN
          </span>
        </Link>
        }

        <span className="cerrar_chico" style={{display:'none'}}>
        {usuario ?
        (
          <Link className="titles" to="/cerrar-sesion">
            <span className="seccion Century Gothic Regular">
              CERRAR
            </span>
          </Link>
        ) 
        :
        (
          <Link className="titles" onClick={() => {setMostrarLogin(true)}} to="">
            <span className="seccion Century Gothic Regular" >
              INICIAR SESIÓN
            </span>
          </Link>
        )
      }
        </span>

        <Link className="titles_registro back_azul Century Gothic Regular" to="/#registro" style={{display:'non'}}>
            REGISTRO
        </Link>
        
      </Col>

      <Col xs={{span:20, offset:0}} lg={{span:0, offset:0}} style={{textAlign:'right'}}>

        <MenuOutlined onClick={() => {setMostrarSubmenu(!mostrar_submenu)}} style={{paddingRight:'10px', fontSize:'20px'}} />

      

      </Col>

      <Col xs={{span:24}} lg={{span:0}} style={{display:'non'}}>
        { mostrar_submenu &&
        <>
          <p >
          <Link to="/" onClick={() => {setMostrarSubmenu(false)}} className="seccion_movil Century Gothic Regular">
            INICIO
          </Link>
        </p>
        <p >
          <Link to="/#agenda" onClick={() => {setMostrarSubmenu(false)}} className="seccion_movil Century Gothic Regular">
            AGENDA
          </Link>
        </p>
        <p style={{display:'none'}}>
          <Link to="/networking" onClick={() => {setMostrarSubmenu(false)}} className="seccion_movil Century Gothic Regular">
            NETWORKING
          </Link>
        </p>
        <p style={{display:'none'}}>
          <Link to="/#conferencistas" onClick={() => {setMostrarSubmenu(false)}} className="seccion_movil Century Gothic Regular">
          CONFERENCISTAS
          </Link>
        </p>
        <p >
          <Link to="/patrocinadores" onClick={() => {setMostrarSubmenu(false)}} className="seccion_movil Century Gothic Regular">
          PATROCINADORES
          </Link>
        </p>

        <p style={{display:'none'}}>

          {usuario ?
          <Link to="/transmision" onClick={() => {setMostrarSubmenu(false)}} className="seccion_movil Century Gothic Regular"  style={{display:'none'}}>
          TRANSMISIÓN
          </Link>
            :
            <Link  onClick={() => {setMostrarLogin(true); setMostrarSubmenu(false);}} to="" className="seccion_movil Century Gothic Regular"  style={{display:'none'}}>
              TRANSMISIÓN
          </Link>  
        }
          
        </p>

        <p style={{display:'none'}}>
        {usuario ?
        (
          <Link to="/cerrar-sesion" onClick={() => {setMostrarSubmenu(false)}} className="seccion_movil Century Gothic Regular">
              CERRAR
          </Link>
        ) 
        :
        (
          <Link  onClick={() => {setMostrarLogin(true); setMostrarSubmenu(false);}} to="" className="seccion_movil Century Gothic Regular">
              INICIAR SESIÓN
          </Link>
        )
      }
        </p>

        <p style={{display:'non'}} className="back_azul">
          <Link to="/#registro" onClick={() => {setMostrarSubmenu(false)}} className="seccion_movil_registro Century Gothic Regular">
          REGISTRO
          </Link>
        </p>

        </>

        }


      
      </Col>
</>
      }
        <Modal
          visible={modal_entrar}
          title=""
          onCancel={ () => { setModalEntrar(false); }}
          footer={null}
        >
          { modal_entrar &&
            <div></div>
          }
        </Modal>
    </Row>

   

    <Modal
          title="INICIA SESIÓN"
          visible={mostrar_login}
          onOk={() => {setMostrarLogin(false)}}
          onCancel={() => {setMostrarLogin(false)}}
          footer={null}
        >
          <Login onResponse={() => {setMostrarLogin(false);  } } onClick={success} />
        </Modal>
    
    </>
    );
}

export default Header;