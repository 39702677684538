import React, {useEffect} from 'react';
import {Switch, Route, useLocation } from 'react-router-dom';
import {Element, scroller} from 'react-scroll';

import RecuperarContrasena from '../sesion/RecuperarContrasena';
import Login from '../registro/Login';
import Error from "../auth/Error";
import Home from '../evento/Home';
import Ponentes from '../ponentes/Conferencistas';
import Registro from '../registro/Registro';
import RegistroCompleto from '../registro/RegistroCompleto';
import Conferencias from '../conferencias/Conferencias';
import Webinars from '../conferencias/Webinars';
import EstadisticaGrafica from '../conferencias/EstadisticaGrafica';

import Patrocinadores from '../patrocinadores/Patrocindaores';
import Networking from '../networking/Networking';

import Demo from '../en-vivo/Demo';

import { AuthContext } from '../../utils/AuthContext';
import Admin from '../admin/Admin';
import VisualizarPreguntas from '../admin/VisualizarPreguntas';
import PopUpPregunta from '../conferencias/PopUpPregunta';
import CerrarSesion from '../sesion/CerrarSesion';
import Header from './Header';
import Footer from './Footer';

import '../../assets/css/general.css';
import VideoPatrocinadores from '../patrocinadores/VideoPatrocinadores';

const secciones_auth = [
  {componente:<Registro tipo_registro="presencial" />, url:'/registro/presencial'},
  {componente:<Registro tipo_registro="virtual" />, url:'/registro/virtual'},
  //{componente:Registro, url:'/'},
  {componente:Ponentes, url:'/conferencistas'},
  //{componente:Demo, url:'/transmision'},
  {componente:RegistroCompleto, url:'/completo'},
  //{componente:Login, url:'/login'},
  //{componente:Conferencias, url:'/conferencias'},
  {componente:Patrocinadores, url:'/patrocinadores'},
  {componente:VideoPatrocinadores, url:'/patrocinadores/:empresa'},
  //{componente:Networking, url:'/networking'},
  {componente:Webinars, url:'/'},
  {componente:Error, utl:'/*'}
];

const secciones = [
  {componente:Admin, url:'/admin-preguntas'},
  {componente:VisualizarPreguntas, url:'/visualizar-preguntas'},
  {componente:EstadisticaGrafica, url:'/estadisticas-graficas'},
  {componente:CerrarSesion, url:'/cerrar-sesion'},
];

export const Master = () => {
  const location = useLocation();

  function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

    useEffect(() => {
          if(location.hash === ''){
            console.log('no ir');
              scroller.scrollTo('header');
          }else{
              console.log('ir');
              console.log(location.hash);
              scroller.scrollTo(location.hash);
          }
    });

    useEffect(() => {
      console.log('se oira a');
      sleep(1000).then(() => {
        if(location.hash === ''){
          console.log('no ir');
            scroller.scrollTo('header');
        }else{
            console.log('ir');
            console.log(location.hash);
            scroller.scrollTo(location.hash);
        }
    });
  },[]);


    let visualizar_secciones_auth = secciones_auth.map((seccion)=>{
      if(seccion.url === '/registro/presencial' || seccion.url === '/registro/virtual'){
        return(
          <Route path={seccion.url} exact >
            {seccion.componente}
          </Route>
        );
      }
      return(
        <Route path={seccion.url} exact component={seccion.componente} />
      );
    });

    let visualizar_secciones = secciones.map((seccion)=>{
      return(
        <Route path={seccion.url} exact component={seccion.componente} />
      );
    });

  return(
    
    <AuthContext >
      <Element name="header"></Element>
      <Header />
      
      <Switch>
      {visualizar_secciones}
        {visualizar_secciones_auth}
        
      </Switch>
      <Footer />
    </AuthContext>

  );
}