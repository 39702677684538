import React from 'react';
import Firebase from '../../utils/firebaseConfig';
import { Tabs , Row, Col, message, Select} from 'antd';
import '../../assets/css/style_demo.css';

const nombre_evento = 'ampip-convencion';
const {Option} = Select;
const app = new Firebase();

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}
class VisualizarPreguntas extends React.Component {

    state = {
        questions: []
      }

      componentDidMount(){

        app.obtener_objetos(nombre_evento+'/preguntas-publico',true,(items) => {
            this.setState({
                questions: items,
              })
        });
      }

      render(){

        return(
            <Row>
                <Col span={24} style={{textAlign:'center'}}>
                    <p style={{fontSize:'30px'}}>Preguntas</p>
                </Col>
                <Col span={20} offset={2}>

                
        {this.state.questions.filter(item => {
            if(item.status === 'aceptada'){
                return true;
            }else{
                return false;
            }
        }).map((item,idx)=>{
          return  item.status === 'aceptada' ? (
          <div key={item.key}>
            <Row align="bottom">
              <Col span={16} style={{textAlign:'left'}}>
            <p style={{color:'black', lineHeight:'1.3', fontSize:'1.2em'}}>
              <span style={{fontFamily:'MyriadProSemB', fontStyle:'italic'}}>{item.nombre}</span> <br />
              <span style={{fontFamily:'MyriadProLight', fontStyle:'italic'}}>{item.organizacion}</span><br/>
              <span style={{fontFamily:'MyriadProBold'}}>{item.pregunta}</span></p>
            </Col>
            <Col span={24}>
                <hr style={{ border:'1px solid black'}} />
                </Col>
            </Row>
          </div>) : null;
        })}
        <br /><br /><br /><br />
      </Col>
            </Row>
        );
        }

}

export default VisualizarPreguntas;