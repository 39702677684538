import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';
import { Element } from 'react-scroll';

import rede_twitter from '../../assets/images/evento/footer/icono_twitter.png';
import rede_ln from '../../assets/images/evento/footer/icono_linked in.png';


import tira from '../../assets/images/sitio/FOOTER/footer_plecastec.png';

import at_t from '../../assets/images/evento/PATROCINADORES/4foroampip2021_at_t.png';
import bancomext from '../../assets/images/evento/PATROCINADORES/4foroampip2021_bancomext.png';
import butler from '../../assets/images/evento/PATROCINADORES/4foroampip2021_butler.png';
import cbre from '../../assets/images/evento/PATROCINADORES/4foroampip2021_cbre.png';
import cfeenergia from '../../assets/images/evento/PATROCINADORES/4foroampip2021_cfeenergia.png';
import cien from '../../assets/images/evento/PATROCINADORES/4foroampip2021_cien.png';
import colliers from '../../assets/images/evento/PATROCINADORES/4foroampip2021_colliers.png';
import copachisa from '../../assets/images/evento/PATROCINADORES/4foroampip2021_copachisa.png';
import creditsuisse from '../../assets/images/evento/PATROCINADORES/4foroampip2021_creditsuisse.png';
import crocsa from '../../assets/images/evento/PATROCINADORES/4foroampip2021_crocsa.png';
import deltack from '../../assets/images/evento/PATROCINADORES/4foroampip2021_deltack.png';
import dragon from '../../assets/images/evento/PATROCINADORES/4foroampip2021_dragon.png';
import empire from '../../assets/images/evento/PATROCINADORES/4foroampip2021_empire.png';
import enestas from '../../assets/images/evento/PATROCINADORES/4foroampip2021_enestas.png';
import engie from '../../assets/images/evento/PATROCINADORES/4foroampip2021_engie.png';
import gesilicones from '../../assets/images/evento/PATROCINADORES/4foroampip2021_gesilicones.png';
import abb from '../../assets/images/evento/PATROCINADORES/4foroampip2021_hitacho_abb.png';
import iberrdrola from '../../assets/images/evento/PATROCINADORES/4foroampip2021_iberrdrola.png';
import igsa from '../../assets/images/evento/PATROCINADORES/4foroampip2021_igsa.png';
import isosindu from '../../assets/images/evento/PATROCINADORES/4foroampip2021_isosindu.png';
import jll from '../../assets/images/evento/PATROCINADORES/4foroampip2021_jll.png';
import kansas from '../../assets/images/evento/PATROCINADORES/4foroampip2021_kansas.png';
import owens from '../../assets/images/evento/PATROCINADORES/4foroampip2021_owens.png';
import solana from '../../assets/images/evento/PATROCINADORES/4foroampip2021_solana.png';
import topmanagment from '../../assets/images/evento/PATROCINADORES/4foroampip2021_topmanagment_OK.png';
import transtelco from '../../assets/images/evento/PATROCINADORES/4foroampip2021_transtelco.png';
import ubicuity from '../../assets/images/evento/PATROCINADORES/4foroampip2021_ubicuity.png';
import hermosillo from '../../assets/images/evento/PATROCINADORES/4foroampip2021hermosillo.png';

import gesilicones_bco from '../../assets/images/evento/4foroampip2021_gesilicones_bco.png';
import isosindu_bco from '../../assets/images/evento/4foroampip2021_isocindu_bco.png';


const socios = [
  {img: at_t,url:'/patrocinadores#at_t'},
  {img: bancomext,url:'/patrocinadores#bancomext'},
  {img: butler,url:'/patrocinadores#butler'},
  {img: cbre,url:'/patrocinadores#cbre'},
  {img: cfeenergia,url:'/patrocinadores#cfeenergia'},
  {img: cien,url:'/patrocinadores#cien'},
  {img: colliers,url:'/patrocinadores#colliers'},
  {img: copachisa,url:'/patrocinadores#copachisa'},
  {img: creditsuisse,url:'/patrocinadores#creditsuisse'},
  {img: crocsa,url:'/patrocinadores#crocsa'},
  {img: deltack,url:'/patrocinadores#deltack'},
  {img: dragon,url:'/patrocinadores#dragon'},
  {img: empire,url:'/patrocinadores#empire'},
  {img: enestas,url:'/patrocinadores#enestas'},
  {img: engie,url:'/patrocinadores#engie'},
  {img: abb,url:'/patrocinadores#abb'},
  {img: iberrdrola,url:'/patrocinadores#iberrdrola'},
  {img: igsa,url:'/patrocinadores#igsa'},
  {img: jll,url:'/patrocinadores#jll'},
  {img: kansas,url:'/patrocinadores#kansas'},
  {img: owens,url:'/patrocinadores#owens'},
  {img: solana,url:'/patrocinadores#solana'},
  {img: topmanagment,url:'/patrocinadores#topmanagment'},
  {img: transtelco,url:'/patrocinadores#transtelco'},
  {img: ubicuity,url:'/patrocinadores#ubicuity'},
  {img: hermosillo,url:'/patrocinadores#hermosillo'},

]

const convencion = [
  {img: gesilicones_bco, url:'/patrocinadores#citius'},
  {img: isosindu_bco, url:'/patrocinadores#citius'},
]

const Footer = () => {

  let location = useLocation();
  console.log('lugar', location)

  let visualizar_socios = socios.map((datos)=>{
    return (
      <Col xs={{span:8}} md={{span:6}} lg={{span:4}}>
        <img src={datos.img} style={{width:'100%', padding:'15px 8px'}} />
      </Col>
    )
  })

  let visualizar_socios_convencion = convencion.map((datos)=>{
    return (
      <Col xs={{span:8}} md={{span:6}} lg={{span:6}}>
        <img src={datos.img} style={{width:'100%'}} />
      </Col>
    )
  })
  
  
  return(
    <>
    {/*location.pathname === '/' ?
    <>
    <Element name="#patrocinadores" />
    <Row className="back_azul">
          <Col span={20} offset={2}>
          <Row align="middle">
          <Col span={1}>
          <p style={{textAlign:'left'}}><hr className="hr_titulos"/></p>
          </Col>
          <Col span={23}>
          <p className="titulo_footer_patrocinadores color_blanco">PATROCINADORES DEL 4º FORO</p>
          </Col>
        </Row>
        
      </Col>
        <Col span={20} offset={2}>
          <Row justify="center" align="middle">
          {visualizar_socios_convencion}
          </Row>
          <br /><br />
        </Col>
      </Row>
      <Row align="middle">
      <Col span={20} offset={2}>
      <Row align="middle">
          <Col span={1}>
          <p style={{textAlign:'left'}}><hr className="hr_titulos"/></p>
          </Col>
          <Col span={23}>
          <p className="color_azul textos_titulos_secciones" style={{textAlign:'left'}}>PATROCINADORES OFICIALES</p>
          </Col>
        </Row>
      </Col>
      <Col span={20} offset={2}>
        <Row justify="center" align="middle">
        {visualizar_socios}
        </Row>
      </Col>
    </Row>
    </>
    :
    <></>
      */}

    <Row>
    <Col span={24} style={{textAlign:'center'}}>
        <br />
        {location.pathname === '/registro' &&
        <p className="color_gris_oscuro texto_contacto_cecilia">
          Para cualquier información contacta a
          <br />
          Cecilia Toribio / Coordinadora de Eventos / ctoribio@ampip.org.mx
        </p>
    }

        
      </Col>

    </Row>
    <Row justify="left" align="middle" className="footer">
      
      <Col xs={{offset:2, span:2}} md={{offset:2,span:1}} style={{padding:'5px 10px'}}>
        <a href="https://twitter.com/ampip" target="_blank"><img className="redes" src={rede_twitter} /></a>
      </Col>
      <Col xs={{offset:0, span:2}} md={{offset:0,span:1}} style={{padding:'5px 10px'}}>
        <a href="https://www.linkedin.com/company/asociaci-n-mexicana-de-parques-industriales" target="_blank"><img className="redes" src={rede_ln} /></a>
      </Col>
      <Col offset={2} span={20} style={{padding:'5px 10px',textAlign:'left'}}>
        <a href="https://ampip.org.mx/es/" target="_blank" className="link_sitio monserrat">www.ampip.org.mx</a>
      </Col>
      <Col span={0} style={{paddingTop:'0px', marginTop:'0px'}}>
        <p className="has_footer">#ExperienciaAMPIP2020</p>
      </Col>
      </Row>
    </>
  );
}

export default Footer;