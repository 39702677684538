import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import '../../assets/css/footer.css';

import at_t from '../../assets/images/evento/PATROCINADORES/4foroampip2021_at_t.png';
import bancomext from '../../assets/images/evento/PATROCINADORES/4foroampip2021_bancomext.png';
import butler from '../../assets/images/evento/PATROCINADORES/4foroampip2021_butler.png';
import cbre from '../../assets/images/evento/PATROCINADORES/4foroampip2021_cbre.png';
import cfeenergia from '../../assets/images/evento/PATROCINADORES/4foroampip2021_cfeenergia.png';
import cien from '../../assets/images/evento/PATROCINADORES/4foroampip2021_cien.png';
import colliers from '../../assets/images/evento/PATROCINADORES/4foroampip2021_colliers.png';
import copachisa from '../../assets/images/evento/PATROCINADORES/4foroampip2021_copachisa.png';
import creditsuisse from '../../assets/images/evento/PATROCINADORES/4foroampip2021_creditsuisse.png';
import crocsa from '../../assets/images/evento/PATROCINADORES/4foroampip2021_crocsa.png';
import deltack from '../../assets/images/evento/PATROCINADORES/4foroampip2021_deltack.png';
import dragon from '../../assets/images/evento/PATROCINADORES/4foroampip2021_dragon.png';
import empire from '../../assets/images/evento/PATROCINADORES/4foroampip2021_empire.png';
import enestas from '../../assets/images/evento/PATROCINADORES/4foroampip2021_enestas.png';
import engie from '../../assets/images/evento/PATROCINADORES/4foroampip2021_engie.png';
import gesilicones from '../../assets/images/evento/PATROCINADORES/4foroampip2021_gesilicones.png';
import abb from '../../assets/images/evento/PATROCINADORES/4foroampip2021_hitacho_abb.png';
import iberrdrola from '../../assets/images/evento/PATROCINADORES/4foroampip2021_iberrdrola.png';
import igsa from '../../assets/images/evento/PATROCINADORES/4foroampip2021_igsa.png';
import isosindu from '../../assets/images/evento/PATROCINADORES/4foroampip2021_isosindu.png';
import jll from '../../assets/images/evento/PATROCINADORES/4foroampip2021_jll.png';
import kansas from '../../assets/images/evento/PATROCINADORES/4foroampip2021_kansas.png';
import owens from '../../assets/images/evento/PATROCINADORES/4foroampip2021_owens.png';
import solana from '../../assets/images/evento/PATROCINADORES/4foroampip2021_solana.png';
import topmanagment from '../../assets/images/evento/PATROCINADORES/4foroampip2021_topmanagment_OK.png';
import transtelco from '../../assets/images/evento/PATROCINADORES/4foroampip2021_transtelco.png';
import ubicuity from '../../assets/images/evento/PATROCINADORES/4foroampip2021_ubicuity.png';
import hermosillo from '../../assets/images/evento/PATROCINADORES/4foroampip2021hermosillo.png';

import gesilicones_bco from '../../assets/images/evento/4foroampip2021_gesilicones_bco.png';
import isosindu_bco from '../../assets/images/evento/4foroampip2021_isocindu_bco.png';
import { LeftOutlined } from '@ant-design/icons';

const socios = [
    { img: at_t, url: '/patrocinadores/at_t', video: '0sWwKIYX8YU', ver: false },
    { img: bancomext, url: '/patrocinadores/bancomext', video: '', ver: false },
    { img: butler, url: '/patrocinadores/butler', video: 'YeynI0NAFjk', ver: false },
    { img: cbre, url: '/patrocinadores/cbre', video: 'URefHbX8rAU', ver: false },
    { img: cfeenergia, url: '/patrocinadores/cfeenergia', video: '', ver: false },
    { img: cien, url: '/patrocinadores/cien', video: '', ver: false },
    { img: colliers, url: '/patrocinadores/colliers', video: 'sOnJCNGE40o', ver: false },
    { img: copachisa, url: '/patrocinadores/copachisa', video: 'spRyx6kxUNU', ver: false },
    { img: creditsuisse, url: '/patrocinadores/creditsuisse', video: '', ver: false },
    { img: crocsa, url: '/patrocinadores/crocsa', video: '', ver: false },
    { img: deltack, url: '/patrocinadores/deltack', video: '', ver: false },
    { img: dragon, url: '/patrocinadores/dragon', video: '', ver: false },
    { img: empire, url: '/patrocinadores/empire', video: '', ver: false },
    { img: enestas, url: '/patrocinadores/enestas', video: '3I31TvtUB2A', ver: false },
    { img: engie, url: '/patrocinadores/engie', video: 'AHFhWNck44w', ver: true },
    { img: abb, url: '/patrocinadores/abb', video: '51UkcYO4J8A', ver: false },
    { img: iberrdrola, url: '/patrocinadores/iberrdrola', video: '', ver: false },
    { img: igsa, url: '/patrocinadores/igsa', video: 'Fzvy5ULWPhg', ver: false },
    { img: jll, url: '/patrocinadores/jll', video: 'oSzZZPLgQUA', ver: true },
    { img: kansas, url: '/patrocinadores/kansas', video: 'deOKzC-eF94', ver: false },
    { img: owens, url: '/patrocinadores/owens', video: '', ver: false },
    { img: solana, url: '/patrocinadores/solana', video: 'tYSWRAKXJ2c', ver: true },
    { img: topmanagment, url: '/patrocinadores/topmanagment', video: 'ierlUKTKFyo', ver: false },
    { img: transtelco, url: '/patrocinadores/transtelco', video: 'DH6f_zT8F-A', ver: false },
    { img: ubicuity, url: '/patrocinadores/ubicuity', video: '', ver: false },
    { img: hermosillo, url: '/patrocinadores/hermosillo', video: 'fCtQIlbcKM8', ver: false },
    {img: gesilicones, url:'/patrocinadores/ge-silicones',video:'MVvvvq07D4U',ver:true},
    {img: isosindu, url:'/patrocinadores/isocinou',video:'fsOwf6cpkWY',ver:true},

]

const convencion = [
    { img: gesilicones_bco, url: '/patrocinadores/citius', video: '', ver: false },
    { img: isosindu_bco, url: '/patrocinadores/citius', video: '', ver: false },
]



const VideoPatrocinadores = () => {
    
const location = useLocation();

let ver_video = socios.filter((video)=>{
    if(location.pathname === video.url){
        return true;
    }else{
        return false;
    }
}).map((frame) => {
    return(
        <>
        <Col xs={{span:10, offset:2}} md={{span:3, offset:2}}>
            <Link to="/patrocinadores" className="btn_regresar"><LeftOutlined /> Regresar</Link>
        </Col>
        <Col xs={{span:10, offset:0}} md={{span:13, offset:0}}>
            <img src={frame.img} style={{width:'200px'}} />
        </Col>
    <Col span={20} offset={2}>
    <div className="contenedor_video">
        <iframe className="video" src={'https://www.youtube.com/embed/'+frame.video+'?rel=0&autoplay=1'} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen;" allowfullscreen="1"></iframe>
    </div>
    </Col>
    </>
)
})

return (
    <>
        <Row align="middle">
            <Col span={20} offset={2}>
                <Row align="middle">
                    {ver_video}
                </Row>
            </Col>
        </Row>
    </>
)
}
export default VideoPatrocinadores;