import React, { useContext, useState, useEffect } from 'react';
import {Link, useLocation } from 'react-router-dom';
import { Row, Col, Modal, Button } from 'antd';
import { Element } from 'react-scroll';
import '../../assets/css/ponentes.css';

import avatar from '../../assets/images/evento/CONFERENCISTAS/ampip_avatar.png';
import salomon from '../../assets/images/evento/CONFERENCISTAS/SalomonChertorivski__ampip2021.png';
import lorenzo from '../../assets/images/evento/CONFERENCISTAS/lorenzo_ampip2021_.png';
import sergio from '../../assets/images/evento/CONFERENCISTAS/SergioLuna__ampip2021.png';
import francisco from '../../assets/images/evento/CONFERENCISTAS/FranciscoSuarez_ampip2021_.png';
import ignacio from '../../assets/images/evento/CONFERENCISTAS/Ignacio_Plancarte_ampip2021_.png';
import gordon from '../../assets/images/evento/CONFERENCISTAS/Gordon Lee_ampip2021.png';
import maricela from '../../assets/images/evento/CONFERENCISTAS/Marisela Connelly_ampip2021.png';
import macario from '../../assets/images/evento/CONFERENCISTAS/MacarioSchettino _ampip2021.png';
import alejandro from '../../assets/images/evento/CONFERENCISTAS/alejandrosanchez_ampip2021-.png';


const expositor = [
  //{nombre:'', img:alberto, hash:'#', puesto:'', conferencia: [''], desc:[''] },
  {nombre:'Salomón Chertorivski', img:salomon, hash:'#salomon-chertorivski', puesto:'Ex-Secretario de Salud y Diputado Federal', conferencia: ['Conferencia: La vida después del COVID'], desc:['Se desempeñó como Secretario de Salud federal de 2011 a 2012, y como Secretario de Desarrollo Económico de la Ciudad de México de 2012 a 2017.','Es presidente del consejo consultivo nacional "Pensando en México" y diputado federal por el partido Movimiento Ciudadano. Como catedrático ha colaborado en el Centro de Investigación y Docencia Económicas (CIDE), así como en el Instituto Tecnológico Autónomo de México, ITAM, y en la Universidad Iberoamericana.','Es Licenciado y Maestro en Economía por el ITAM, y Maestro en Políticas Públicas por la Universidad de Harvard.'] },
  {nombre:'Lorenzo Dominique Berho', img:lorenzo, hash:'#alejandro-sanchez', puesto:'Director General, Vesta / Presidente, AMPIP ', conferencia: ['Asamblea General de Socios AMPIP'], desc:['Como Director General de Corporación Inmobiliaria Vesta, es responsable de establecer la estrategia general de la organización a corto, mediano y largo plazo, al igual que de asegurar su correcta ejecución por parte de las diferentes áreas, fungiendo como vínculo principal con el Consejo de Administración.','En febrero del 2020 fue electo Presidente de la Asociación Mexicana de Parques Industriales Privados, A.C., AMPIP, donde también ha sido Consejero.','Es Ingeniero Industrial por la Universidad Iberoamericana, con Maestría en Ciencias de Bienes Raíces por la Universidad de San Diego y forma parte del Young Leaders Group del ULI (Urban Land Institute).'] },
  {nombre:'Sergio Luna', img:sergio, hash:'#sergio-luna', puesto:'Analista Económico', conferencia: ['Conferencia: Hacia dónde va la economía mexicana'], desc:['Autor, consultor y comentarista en temas económicos y de mercados financieros, es miembro del consejo de BondBlox y asesor Senior para México en Electa Capital Partners.','Desarrolló una carrera de 33 años en el Departamento de Estudios Económicos del Banco Nacional de México, donde los últimos 15 fungió como Economista en Jefe. Fue representante de México en el grupo de pronosticadores del Pacific Economic Council, y participó en la inclusión de México en el World Government Bond Index.','Ha publicado en revistas especializadas y contribuido en diversos libros. Su actividad docente incluye la UNAM, el CIDE y la Universidad de Londres.','Es Licenciado en Economía por la UNAM, con Maestría (1991) y Doctorado (1996) en Ciencias Económicas, por la Universidad de Londres en el Reino Unido.'] },
  {nombre:'Francisco Suárez', img:francisco, hash:'#francisco-suarez', puesto:'Director de Global Research - Equity LATAM, Scotiabank', conferencia: ['Panel: Mercado de capitales en la visión de los expertos'], desc:["Es analista para los sectores de cemento, construcción, bienes raíces e infraestructura en Latinoamérica, desde 2013. Dirigió el inicio de la Investigación de Inversiones Ambientales, Sociales y de Gobernanza (ESG) en marzo de 2018.","Cuenta con 23 años de experiencia laboral en la industria financiera: 19 como analista de acciones de venta para Grupo Financiero Banorte y HSBC; y 4 como analista de crédito en Standard & Poor's. También encabezó un equipo de investigación para Actinver.",'Su experiencia sectorial incluye empresas industriales, siderúrgicas y mineras e instituciones financieras.','Es Licenciado en Economía por el ITAM, con numerosos diplomas en finanzas corporativas, análisis de seguridad e inversiones.'] },
  {nombre:'Ignacio Plancarte', img:ignacio, hash:'#ignacio-plancarte', puesto:'Director de Financiamiento a la IMMEX, Bancomext', conferencia: ['Panel: Mercado de capitales en la visión de los expertos'], desc:['Se ha desempeñado durante los últimos 19 años en el área de Promoción de Crédito de Bancomext. Desde 2016 es responsable del programa de Financiamiento para Desarrollo de Infraestructura Industrial, donde administra una cartera de aproximadamente 1.5 billones de dólares en créditos otorgados a activos inmobiliarios.','Es Licenciado en Administración Financiera por la Universidad Autónoma de Aguascalientes y cuenta con una Maestría en Negocios por el Instituto Tecnológico Autónomo de México, ITAM.'] },
  {nombre:'Alejandro Sanchez Aldana', img:alejandro, hash:'#alejandro-sanchez', puesto:'Director de Finanzas Corporativas en la Banca Comercial, Citibanamex', conferencia: ['Panel: Mercado de capitales en la visión de los expertos'], desc:['Es actualmente responsable del Equipo de Finanzas Corporativas para empresas dentro de la Banca Comercial de Citibanamex, función que realiza con un enfoque en generación, estructuración y ejecución de transacciones episódicas de financiamiento a través de deuda. ','Previamente, al interior de Citibanamex, se desempeñó de 2005 a 2021 como especialista en Ofertas Públicas de Deuda tanto en el mercado local (por medio de Certificados Bursátiles), como en mercados internacionales.','Es Ingeniero Industrial por la Universidad Iberoamericana, y cuenta con un MBA otorgado por el Instituto de Empresa, IE Business School, de España.'] },
  {nombre:'Gordon Lee', img:gordon, hash:'#gordon-lee', puesto:'Director para Latinoamérica, BTG Pactual', conferencia: ['Panel: Mercado de capitales en la visión de los expertos'], desc:['Es socio MD en BTG Pactual, donde se desempeña como estratega y analista Senior en México cubriendo los sectores de bienes raíces, cemento y construcción y medios de comunicación de América Latina.','Ha cubierto las acciones de América Latina desde 1994 en una variedad de sectores, siempre con un enfoque en el sector inmobiliario y de la construcción.','Antes de unirse a BTG Pactual en 2012, pasó un tiempo en UBS/UBS Pactual, Goldman Sachs, Deutsche Bank y Barings. ','Es Licenciado en Economía por la Universidad de Pennsylvania. '] },
  {nombre:'Marisela Connelly', img:maricela, hash:'#marisela-onnelly', puesto:'Investigadora, Centro de Estudios de Asia y África, El Colegio de México', conferencia: ['Conferencia: El rol de China en la era Biden'], desc:['Imparte cursos sobre China y Asia Pacífico en Licenciatura, Maestría y Doctorado.','Publicaciones recientes: Asia Pacífico 2019, coordinadora del Anuario y autora del capítulo “Deterioro del medio ambiente en China: respuesta del Estado y sociedad” en libro Regiones Internacionales. Una perspectiva Transversal; “Posición de China frente al conflicto sirio”, en libro Siria en el torbellino: insurrección, guerras y geopolítica; Historia de Taiwán, Colmex, 2014; “Producción de energía limpia vs protección del medio ambiente: Proyecto del Río Nu", Estudios de Asia y África, Vol. XLIX, No. 1, ene–abr 2014; "China and Latin America: The Economic Dimension", en Multiregionalism and Multilateralism.','Realizó sus estudios de doctorado en Columbia University, New York. Ha realizado múltiples estancias de investigación en Beijing, Shanghai, Hong Kong y Taiwán. Reconocimiento a trayectoria académica por la Universidad de Colima 2010.'] },
  {nombre:'Macario Schettino', img:macario, hash:'#macario-schettino', puesto:'Analista Económico-Político', conferencia: ['Conferencia: México frente al desafío del COVID- 19'], desc:['Profesor e investigador de la Escuela de Gobierno del Instituto Tecnológico de Monterrey, ITESM. Ha sido directivo en instituciones académicas y medios de comunicación y asesor y consultor para partidos políticos, empresas y gobiernos. Participa en la mesa semanal “Dinero y Poder”, en canal 11.','Escribe la columna “Fuera de la Caja”, en El Financiero; publica un Podcast con ese mismo nombre, análisis semanales en Patreon, y toda su producción puede encontrarse en macario.substack.com.','Ha escrito dos decenas de libros, los más recientes, El Fin de la Confusión y Economía en un Día, ambos con editorial Paidós.','Es Ingeniero Químico, Maestro en Economía, Doctor en Negocios y candidato a Doctor en Historia.'] },
  
]

const Conferencistas = () =>{


  let location = useLocation();


  let ver_ponentes = expositor.map((seccion)=>{
    return(
      <>
      <Element name={seccion.hash}></Element>
      <Row className="contenedor_conferencista">
        <Col xs={{span:20,offset:2}} md={{span:8,offset:0}} className="contenedor_img_conferencista">
          <img src={seccion.img} className="img_conferencista" />
        </Col>
        <Col xs={{span:20,offset:2}} md={{span:16,offset:0}}>
          <p className="nombre_conferencista">
            {seccion.nombre}
          </p>
          <p className="puesto_conferencista">
            {seccion.puesto}
          </p><br />
          <p className="p_text">
            <span className="conferencia_text">
          {seccion.conferencia.map((parrafo,index) => {
            if(index === 0){
                return(
                  <>{parrafo}</>
                  );
                }else{
                  return(
                  <><br />{parrafo}</>
                  );
                }
              })}
          </span>
          </p><br />
          <p className="parrafo_confeencista">
            {seccion.nombre === 'Marisela Connelly' ?
            <>Investigadora, Centro de Estudios de Asia y África, El Colegio de México.
            <br /><br />
            Imparte cursos sobre China y Asia Pacífico en Licenciatura, Maestría y Doctorado.
            <br /><br />
            Publicaciones recientes: <i>Asia Pacífico 2019</i>, coordinadora del Anuario y autora del capítulo “Deterioro del medio ambiente en China: respuesta del Estado y sociedad” en el libro <i>Regiones Internacionales. Una perspectiva Transversal;</i> “Posición de China frente al conflicto sirio”, en el libro <i>Siria en el torbellino: insurrección, guerras y geopolítica; Historia de Taiwán,</i> Colmex, 2014; “Producción de energía limpia vs protección del medio ambiente: Proyecto del Río Nu", <i>Estudios de Asia y África,</i> Vol. XLIX, No. 1, ene–abr 2014; "China and Latin America: The Economic Dimension", en <i>Multiregionalism and Multilateralism.</i>
            <br /><br />
            Realizó sus estudios de doctorado en Columbia University, New York. Ha realizado múltiples estancias de investigación en Beijing, Shanghai, Hong Kong y Taiwán. Reconocimiento a trayectoria académica por la Universidad de Colima 2010.
            </>
            :
            seccion.desc.map((parrafo,index) => {
              if(index === 0){
                  return(
                    <>{parrafo}</>
                    );
                  }else{
                    return(
                    <><br /><br />{parrafo}</>
                    );
                  }
                })}
            
            </p>
        </Col>
      </Row>
      </>
    );})


    useEffect(() => {
      if(location.hash !== '' && location.hash !== null && location.hash !== undefined){
          window.location.href  = '/conferencistas'+location.hash;
      }
      
    },[]);

  return(
    <>
    <Row justify="center" align="middle">
    <Col span={20} offset={2}>
      <Row align="middle">
          <Col span={1}>
            <p style={{textAlign:'left'}}><hr className="hr_titulos"/></p>
          </Col>
          <Col span={23}>
          <p className="color_azul textos_titulos_secciones" style={{textAlign:'left'}}>CONFERENCISTAS</p>
          </Col>
        </Row>
      </Col>
      <Col span={22}>
      <Row>
      {ver_ponentes}
      </Row>
      </Col>
      
    </Row>
    
    </>
  );
}

export default Conferencistas;