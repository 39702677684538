import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import dia1 from '../../assets/images/evento/2_HOME/btn_10 nov.jpg';
import dia2 from '../../assets/images/evento/2_HOME/btn_11nov.jpg';

import '../../assets/css/agenda.css';
import img_registrate_master_class from '../../assets/images/sitio/1_INICIO/registrate_masterclass_azul.png';

const uno  = ["Eduardo Suárez, Vicepresidente, LATAM Economics", "Scotiabank, Global Banking and Markets"]

const primer = [
  {hora:'16:30 – 18:30', titulo:['Reunión del Consejo Directivo (Sólo consejeros)'], desc:[''], preseta:'', personas:'', conferencista:'', conferen:[{nombres:'',cargo:''}] , entrar:'', dia:'1', info:''},
  {hora:'19:30', titulo:['Cena de Bienvenida','Conferencia: La vida después del COVID'], desc:[''], preseta:'', personas:'', conferencista:' ', conferen:[{nombres:'Salomón Chertorivski, ', cargo:'Ex-Secretario de Salud y Diputado Federal'}] , entrar:'', dia:'1', info:''},
  
]
const segundo = [
  {hora:'08:30 – 10:00', titulo:['Asamblea General de Socios AMPIP'], desc:['Informe de la Presidencia, 2020-2021','Elección del nuevo Presidente de la Asociación para el periodo 2022-2023','Mensaje del Presidente electo'], preseta:'', personas:'', conferencista:'', conferen:[{nombre:'',cargo:''}] , entrar:'', dia:'2', info:''},
  {hora:'10:00 – 10:15', titulo:['Receso corto'], desc:[''], preseta:'', personas:'', conferencista:'', conferen:[{nombres:'',cargo:''}]  , entrar:'', dia:'2', info:''},
  {hora:'10:15 – 11:00', titulo:['Conferencia: Hacia dónde va la economía mexicana '], desc:[''], preseta:'', personas:'', conferencista:' ', conferen:[{nombres:'Sergio Luna, ', cargo:'Analista económico '}] , entrar:'', dia:'2', info:''},
  {hora:'11:00 – 12:00', titulo:['Panel: Mercado de capitales en la visión de los expertos '], desc:[''], preseta:'', personas:'', conferencista:' ', conferen:[{nombres:'Francisco Suárez, ',cargo: 'Director de Global Research - Equity LATAM, Scotiabank'},{nombres:'Ignacio Plancarte, ', cargo:'Director de Financiamiento a la IMMEX, Bancomext'},{nombres:'Alejandro Sanchez Aldana,', cargo:'Director de Finanzas Corporativas en la Banca Comercial, Citibanamex'},{nombres:'Moderador: Gordon Lee,',cargo: 'Director para Latinoamérica, BTG Pactual'}] , entrar:'', dia:'2', info:''},
  {hora:'12:00 – 12:30', titulo:['Receso largo'], desc:[''], preseta:'', personas:'', conferencista:'', conferen:[{nombres:'',cargo:''}]  , entrar:'', dia:'2', info:''},
  {hora:'12:30 – 13:30', titulo:['Conferencia: El rol de China en la era Biden'], desc:[''], preseta:'', personas:'', conferencista:' ', conferen:[{nombres:'Marisela Connelly, ', cargo:'Investigadora, Centro de Estudios de Asia y África, El Colegio de México'}] , entrar:'', dia:'2', info:''},
  {hora:'13:30 – 14:30', titulo:['Conferencia: México frente al desafío del COVID- 19  '], desc:[''], preseta:'', personas:'', conferencista:' ', conferen:[{nombres:'Macario Schettino, ',cargo:'Analista económico-político'}] , entrar:'', dia:'2', info:''},
  {hora:'14:30 h', titulo:['Comida Networking'], desc:[''], preseta:'', personas:'', conferencista:' ', conferen:[{nombres:'',cargo:''}] , entrar:'', dia:'2', info:''},
  //{hora:'', titulo:[''], desc:'', preseta:'', personas:'', conferencista:' ', conferen:[{nombres:'',cargo:''}] , entrar:'', dia:'2', info:''},
  
]
const Agenda = () => {
  const [agenda, setRevista] = useState(false);

  let visualizar_agenda = primer.map((datos, index)=>{
    return(
      <Row>
        <Col xs={{span:7, offset:0}} md={{span:6, offset:0}} className={index < primer.length - 1 ? "circulo_agenda borde_agenda" : "circulo_agenda borde_agenda"}>
          <p style={{marginBottom:'0px'}} className="hora_agenda" >{datos.hora}</p>
        </Col>
        <Col xs={{span:17, offset:0}} md={{span:18, offset:0}} className={index < primer.length - 1 ? "linea_agenda" : "linea_agenda_cero" } >
          <p className="titulo_agenda">
          {datos.titulo.map((parrafo,index) => {
        if(index === 0){
          return(
            <>{parrafo}</>
            );
        }else{
          return(
            <><br />{parrafo}</>
            );
        }
        })}
        </p>
          {datos.desc !== '' &&
          <p className="desc_agenda">
            {datos.desc.map((parrafo,index) => {
        if(index === 0){
          return(
            <>{parrafo}</>
            );
        }else{
          return(
            <><br />{parrafo}</>
            );
        }
        })}
        </p>
          }

{datos.preseta !== '' &&
          <p className="desc_agenda"><strong>{datos.preseta}</strong><br />
          {datos.personas}</p>
          }

{datos.conferencista !== '' &&

<>

{datos.link &&
  <br />
}

  
  

          <p className="desc_agenda"><strong>{datos.conferencista}</strong><br />

          {datos.conferen.map((parrafo,index) => {

              return(
                <><strong>{parrafo.nombres}</strong> {parrafo.cargo}<br /></>
                );
            
          })}
          
          </p>

          </>
          }
          
          {datos.entrar !== '' &&
          <Link to={datos.entrar}></Link>
          }

          {datos.link &&
          <p style={{textAlign:'left', fontSize:'18px'}}>
            
            <a target="_blank" className="back_azul" href={datos.link}>
              <img src={img_registrate_master_class} className="img_registrar_master_class" />
            </a>
            
          </p>
          
          }
          
          
          
          
        </Col>

      </Row>
    )
  })
  
  let visualizar_agenda_dos = segundo.map((datos, index)=>{
    return(
      <Row >
        <Col xs={{span:7, offset:0}} md={{span:6, offset:0}} className={index < segundo.length - 1 ? "circulo_agenda borde_agenda" : "circulo_agenda borde_agenda"}>
          <p style={{marginBottom:'0px'}} className="hora_agenda" >{datos.hora}</p>
        </Col>
        <Col xs={{span:17, offset:0}} md={{span:18, offset:0}} className={index < segundo.length - 1 ? "linea_agenda" : "linea_agenda_cero" }>
          <p className="titulo_agenda">{datos.titulo}</p>
          {
            datos.desc !== '' &&
            <p className="desc_agenda">
              {index === 0 ?
              <>Bienvenida, a cargo de <strong>Lorenzo D. Berho</strong>, Presidente de la AMPIP<br /></>
            :
            ''}
            {datos.desc.map((parrafo,index) => {
        if(index === 0){
          return(
            <>{parrafo}</>
            );
        }else{
          return(
            <><br />{parrafo}</>
            );
        }
        })}
        </p>
          }

{
            datos.preseta !== '' &&
            <p className="desc_agenda"><strong>{datos.preseta}</strong><br />
            {datos.personas}</p>
          }

{
            datos.conferencista !== '' &&
            <p className="desc_agenda"><strong>{datos.conferencista}</strong><br />

          {datos.conferen.map((parrafo,index) => {

          return(
            <><strong>{parrafo.nombres}</strong> {parrafo.cargo}<br /></>
            );
          })}
          
          </p>
          }
          {datos.entrar !== '' &&
          <Link to={datos.entrar}></Link>
          }
          
          
          
          
          
        </Col>

      </Row>
    )
  })

  return(
    <Row>
      <Col span={20} offset={2}>
      <Row align="middle">
          <Col span={1}>
            <p style={{textAlign:'left'}}><hr className="hr_titulos"/></p>
          </Col>
          <Col span={23}>
          <p className="color_azul textos_titulos_secciones" style={{textAlign:'left'}}>AGENDA</p>
          </Col>
        </Row>
      </Col>
      <Col xs={{span:20, offset:2}} md={{span:10, offset:2}} onClick={() => setRevista(false)}>
        <img src={dia1} className="img_dia_agenda"/>
      </Col>
      <Col xs={{span:20, offset:2}} md={{span:10, offset:0}} onClick={() => setRevista(true)}>
      <img src={dia2} className="img_dia_agenda" />
      </Col>
      {agenda === false ?
      (
        <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
        <p className="text_dias color_gris_oscuro">10 de noviembre 2021</p>
        {visualizar_agenda}
        </Col>
      )
      :
      (
      <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
        <p className="text_dias color_gris_oscuro">11 de noviembre 2021</p>
        {visualizar_agenda_dos}
      </Col>
      )
      }

    </Row>
    )
}
export default Agenda;