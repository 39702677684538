import React from 'react';
import '../../assets/css/admin.css';
import Firebase from '../../utils/firebaseConfig';
import { Button, Row, Col, Select, notification  } from "antd";
import logo_thethree from '../../assets/images/admin/logo-thethree.png';
import logo_impac from '../../assets/images/admin/logo-impactotal.png';

const nombre_webinar = 'ampip-convencion';

const mensaje = (tipo, titulo, texto) =>{
  notification[tipo]({
      message: titulo,
      description: texto,
      duration: 10,
    });
}


const firebase = new Firebase();

const { Option } = Select;

class Admin extends React.Component {

  state = {
    questions: [],
    preguntas:[]
  }

  componentDidMount(){
    firebase.obtener_objetos(nombre_webinar+'/preguntas-publico',true,(data)=>{
        
      this.setState({
        questions: data,
      })
    });

    firebase.obtener_objetos(nombre_webinar+'/preguntas-vivo',false,(data) => {
      
      this.setState({
        preguntas: data,
      })
    });

  }

  aceptQuestion = (item) => {

    item['status'] = 'aceptada';

    firebase.escribir(nombre_webinar+'/preguntas-publico', item.key, item, ()=>{
      
    });
  };

  rejectQuestion = (item) => {

    item['status'] = 'rechazada';

    firebase.escribir(nombre_webinar+'/preguntas-publico', item.key, item, ()=>{

    });
  };

  answerQuestion = (item) => {

    item['status'] = 'contestada';

    firebase.escribir(nombre_webinar+'/preguntas-publico', item.key, item, ()=>{

    });
  };

  retrieveQuestion = (item) => {

    item['status'] = 'pendiente';

    firebase.escribir(nombre_webinar+'/preguntas-publico', item.key, item, ()=>{

    });
  };

  cambiarPreguntaPopUp = (e) => {

    firebase.escribir(nombre_webinar+'/configuracion','1234567890',{pregunta_popup: e}, ()=> {
      mensaje('success','Se actualizo la pregunta','');
    } );

  }

  render(){

    let preguntas = this.state.preguntas.map(pregunta => {

      return(
      <Option value={pregunta.num_pregunta}>{pregunta.pregunta}</Option>
      );
    });

    let { questions } = this.state;

    return (
      <>
      <Row>
        <Col span={20} offset={2}>
          <br />
        <p className="admin_titulo">ENCUESTA SATISFACCIÓN</p>
        <Select defaultValue={0} style={{ width: '100%' }} onChange={this.cambiarPreguntaPopUp}>
          <Option value={0}>Desactivar</Option>
          { preguntas }
        </Select>
        </Col>
      </Row>
      <Row>

        <Col span={7} offset={1}>
          <p className="admin_titulo">PREGUNTAS</p>
        </Col>
        <Col span={2} offset={11}>
            <img src={logo_thethree} style={{ width:'100px' }} />
        </Col>
        <Col span={2} offset={0}>
            <img src={logo_impac} style={{ width:'100px' }} />
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">PENDIENTES</p>
            </Col>
            <Col span={24} >
            {questions.map((item,idx)=>{
              return  item.status === 'pendiente' ? (
              <div className="questionContainer" key={item.key}>
                <p className="question"><span className="nombre_pregunta">{item.nombre}</span><br /> {item.organizacion}</p>
                <p className="question"><strong>{item.pregunta}</strong></p>
                <p className="botones">
                <button className="acceptButton" onClick={()=>{
                    this.aceptQuestion(item);
                  }}>ACEPTAR</button>
                  <button className="rejectButton" onClick={()=>{
                    this.rejectQuestion(item);
                  }}>RECHAZAR</button>
                </p>
                </div>) : null;
            })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">ACEPTADAS</p>
            </Col>
            <Col span={24}>
            {questions.map((item,idx)=>{
          return  item.status === 'aceptada' ? (
          <div className="questionContainer" key={item.key}>
            <p className="question"><span className="nombre_pregunta">{item.nombre}</span><br /> {item.organizacion}</p>
            <p className="question"><strong>{item.pregunta}</strong></p>
            <p className="botones">
            <button className="answerButton" onClick={()=>{
              this.answerQuestion(item);
            }}>CONTESTADA</button>
            </p>
          </div>) : null;
        })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">RECHAZADAS</p>
            </Col>
            <Col span={24}>
            {questions.map((item,idx)=>{
            return  item.status === 'rechazada' ? (
            <div className="questionContainer" key={item.key}>
              <p className="question"><span className="nombre_pregunta">{item.nombre}</span> <br /> {item.organizacion}</p>
              <p className="question"><strong>{item.pregunta}</strong></p>
              <p className="botones">
                <button className="retrieveButton" onClick={()=>{
              this.retrieveQuestion(item);
            }}>RECUPERAR</button>
            </p>
          </div>) : null;
        })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">CONTESTADAS</p>
            </Col>
            <Col span={24} >
            {questions.map((item,idx)=>{
            return  item.status === 'contestada' ? (
            <div className="questionContainer" key={item.key}>
            <p className="question"><span className="nombre_pregunta">{item.nombre}</span> <br /> {item.organizacion}</p>
            <p className="question"><strong>{item.pregunta}</strong></p>
          </div>) : null;
        })}
            </Col>
          </Row>

          <br /><br /><br /><br />
        </Col>
        
      </Row>
    </>)
  }
}

export default Admin;