import React, { useState } from 'react';
import { Row, Col } from 'antd';
import '../../assets/css/sala.css';
import { Link } from 'react-router-dom';

import avatar from '../../assets/images/evento/CONFERENCISTAS/ampip_avatar.png';
import avatar_hover from '../../assets/images/evento/CONFERENCISTAS/ampip_avatar_over.png';
import salomon from '../../assets/images/evento/CONFERENCISTAS/SalomonChertorivski__ampip2021.png';
import salomon_hover from '../../assets/images/evento/CONFERENCISTAS/SalomonChertorivski_ampip2021_over.png';
import lorenzo from '../../assets/images/evento/CONFERENCISTAS/lorenzo_ampip2021_.png';
import lorenzo_hover from '../../assets/images/evento/CONFERENCISTAS/lorenzo_ampip2021_over-.png';
import sergio from '../../assets/images/evento/CONFERENCISTAS/SergioLuna__ampip2021.png';
import sergio_hover from '../../assets/images/evento/CONFERENCISTAS/SergioLuna__ampip2021_over.png';
import francisco from '../../assets/images/evento/CONFERENCISTAS/FranciscoSuarez_ampip2021_.png';
import francisco_hover from '../../assets/images/evento/CONFERENCISTAS/FranciscoSuarez_ampip2021_over.png';
import ignacio from '../../assets/images/evento/CONFERENCISTAS/Ignacio_Plancarte_ampip2021_.png';
import ignacio_hover from '../../assets/images/evento/CONFERENCISTAS/Ignacio_Plancarte__ampip2021_over.png';
import gordon from '../../assets/images/evento/CONFERENCISTAS/Gordon Lee_ampip2021.png';
import gordon_hover from '../../assets/images/evento/CONFERENCISTAS/Gordon Lee_ampip2021_over.png';
import maricela from '../../assets/images/evento/CONFERENCISTAS/Marisela Connelly_ampip2021.png';
import maricela_hover from '../../assets/images/evento/CONFERENCISTAS/Marisela Connelly_ampip2021_over.png';
import macario from '../../assets/images/evento/CONFERENCISTAS/MacarioSchettino _ampip2021.png';
import macario_hover from '../../assets/images/evento/CONFERENCISTAS/MacarioSchettino _ampip2021_over.png';
import alejandro from '../../assets/images/evento/CONFERENCISTAS/alejandrosanchez_ampip2021-.png';
import alejandro_hover from '../../assets/images/evento/CONFERENCISTAS/alejandrosanchez_ampip2021-over.png';


const expositor = [
  //{nombre:'', img: avatar,img_hover:avatar_hover, hash:'/conferencistas#' },
  {nombre:'Salomón Chertorivski',empresa:'Ex-Secretario de Salud y Diputado Federal', img: salomon,img_hover:salomon_hover, hash:'/conferencistas#salomon-chertorivski'},
  {nombre:'Lorenzo Dominique Berho', empresa:'Director General, Vesta / Presidente, AMPIP', img: lorenzo,img_hover:lorenzo_hover, hash:'/conferencistas#alejandro-sanchez' },
  {nombre:'Sergio Luna', empresa:'Analista económico', img: sergio,img_hover:sergio_hover, hash:'/conferencistas#sergio-luna' },
  {nombre:'Francisco Suárez', empresa:'Director de Global Research - Equity LATAM, Scotiabank', img: francisco,img_hover:francisco_hover, hash:'/conferencistas#francisco-suarez' },
  {nombre:'Ignacio Plancarte', empresa:'Director de Financiamiento a la IMMEX, Bancomext', img: ignacio,img_hover:ignacio_hover, hash:'/conferencistas#ignacio-plancarte' },
  {nombre:'Alejandro Sanchez Aldana', empresa:'Director de Finanzas Corporativas en la Banca Comercial, Citibanamex', img: alejandro,img_hover:alejandro_hover, hash:'/conferencistas#alejandro-sanchez' },
  {nombre:'Gordon Lee', empresa:'Director para Latinoamérica, BTG Pactual', img: gordon,img_hover:gordon_hover, hash:'/conferencistas#gordon-lee' },
  {nombre:'Marisela Connelly', empresa:'Investigadora, Centro de Estudios de Asia y África, El Colegio de México', img: maricela,img_hover:maricela_hover, hash:'/conferencistas#marisela-connelly' },
  {nombre:'Macario Schettino', empresa:'Analista Económico-Político', img: macario,img_hover:macario_hover, hash:'/conferencistas#macario-schettino' },
]
const Ponentes = (props) =>{
  const [hover, setHover] = useState(false);

  let ver_ponentes = expositor.map((seccion,index)=>{
    return(
        <Col xs={12} md={8} lg={8}>
        <Link to={seccion.hash} onMouseEnter={() => setHover(index)} onMouseLeave={()=>setHover(false)}>
          <img src={hover === index ? seccion.img_hover : seccion.img} className="img_ponente_home" />
          <p className="info_ponente_home color_gris_oscuro"><span className="texto_mayus"><strong>{seccion.nombre}</strong></span><br />
          {seccion.empresa}</p>
        </Link>
      </Col>
    );})

    return(
      <>
      <Row justify="center" align="middle" className="contenedor_ponentes">
      <Col span={20} offset={2}>
      <Row align="middle">
          <Col span={1}>
            <p style={{textAlign:'left'}}><hr className="hr_titulos"/></p>
          </Col>
          <Col span={23}>
          <p className="color_azul textos_titulos_secciones" style={{textAlign:'left'}}>CONFERENCISTAS</p>
          </Col>
        </Row>
      </Col>
        <Col span={20}>
          <Row justify="center">
            {ver_ponentes}
          </Row>
        </Col>
        
      </Row>
      
      </>
  );
}
export default Ponentes;