import React from 'react';
import {Link} from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';
import { Element } from 'react-scroll';

import img_sitio from '../../assets/images/sitio/3_PATROCINADORES/btn-visitarsitio.png';
import img_bronchure from '../../assets/images/sitio/3_PATROCINADORES/btn-verbrochure.png';


import at_t from '../../assets/images/evento/PATROCINADORES/4foroampip2021_at_t.png';
import bancomext from '../../assets/images/evento/PATROCINADORES/4foroampip2021_bancomext.png';
import butler from '../../assets/images/evento/PATROCINADORES/4foroampip2021_butler.png';
import cbre from '../../assets/images/evento/PATROCINADORES/4foroampip2021_cbre.png';
import cfeenergia from '../../assets/images/evento/PATROCINADORES/4foroampip2021_cfeenergia.png';
import cien from '../../assets/images/evento/PATROCINADORES/4foroampip2021_cien.png';
import colliers from '../../assets/images/evento/PATROCINADORES/4foroampip2021_colliers.png';
import copachisa from '../../assets/images/evento/PATROCINADORES/4foroampip2021_copachisa.png';
import creditsuisse from '../../assets/images/evento/PATROCINADORES/4foroampip2021_creditsuisse.png';
import crocsa from '../../assets/images/evento/PATROCINADORES/4foroampip2021_crocsa.png';
import deltack from '../../assets/images/evento/PATROCINADORES/4foroampip2021_deltack.png';
import dragon from '../../assets/images/evento/PATROCINADORES/4foroampip2021_dragon.png';
import empire from '../../assets/images/evento/PATROCINADORES/4foroampip2021_empire.png';
import enestas from '../../assets/images/evento/PATROCINADORES/4foroampip2021_enestas.png';
import engie from '../../assets/images/evento/PATROCINADORES/4foroampip2021_engie.png';
import gesilicones from '../../assets/images/evento/PATROCINADORES/4foroampip2021_gesilicones.png';
import abb from '../../assets/images/evento/PATROCINADORES/4foroampip2021_hitacho_abb.png';
import iberrdrola from '../../assets/images/evento/PATROCINADORES/4foroampip2021_iberrdrola.png';
import igsa from '../../assets/images/evento/PATROCINADORES/4foroampip2021_igsa.png';
import isosindu from '../../assets/images/evento/PATROCINADORES/4foroampip2021_isosindu.png';
import jll from '../../assets/images/evento/PATROCINADORES/4foroampip2021_jll.png';
import kansas from '../../assets/images/evento/PATROCINADORES/4foroampip2021_kansas.png';
import owens from '../../assets/images/evento/PATROCINADORES/4foroampip2021_owens.png';
import solana from '../../assets/images/evento/PATROCINADORES/4foroampip2021_solana.png';
import topmanagment from '../../assets/images/evento/PATROCINADORES/4foroampip2021_topmanagment_OK.png';
import transtelco from '../../assets/images/evento/PATROCINADORES/4foroampip2021_transtelco.png';
import ubicuity from '../../assets/images/evento/PATROCINADORES/4foroampip2021_ubicuity.png';
import hermosillo from '../../assets/images/evento/PATROCINADORES/4foroampip2021hermosillo.png';

import gesilicones_bco from '../../assets/images/evento/4foroampip2021_gesilicones_bco.png';
import isosindu_bco from '../../assets/images/evento/4foroampip2021_isocindu_bco.png';

const socios = [
  { img: at_t, url: '/patrocinadores/at_t', video: '0sWwKIYX8YU', ver: true },
  { img: bancomext, url: '/patrocinadores/bancomext', video: '', ver: false },
  { img: butler, url: '/patrocinadores/butler', video: 'YeynI0NAFjk', ver: true },
  { img: cbre, url: '/patrocinadores/cbre', video: 'URefHbX8rAU', ver: true },
  { img: cfeenergia, url: '/patrocinadores/cfeenergia', video: '', ver: false },
  { img: cien, url: '/patrocinadores/cien', video: '', ver: false },
  { img: colliers, url: '/patrocinadores/colliers', video: 'sOnJCNGE40o', ver: true },
  { img: copachisa, url: '/patrocinadores/copachisa', video: 'spRyx6kxUNU', ver: true },
  { img: creditsuisse, url: '/patrocinadores/creditsuisse', video: '', ver: false },
  { img: crocsa, url: '/patrocinadores/crocsa', video: '', ver: false },
  { img: deltack, url: '/patrocinadores/deltack', video: '', ver: false },
  { img: dragon, url: '/patrocinadores/dragon', video: '', ver: false },
  { img: empire, url: '/patrocinadores/empire', video: '', ver: false },
  { img: enestas, url: '/patrocinadores/enestas', video: '3I31TvtUB2A', ver: true },
  { img: engie, url: '/patrocinadores/engie', video: 'AHFhWNck44w', ver: true },
  { img: abb, url: '/patrocinadores/abb', video: '51UkcYO4J8A', ver: true },
  { img: iberrdrola, url: '/patrocinadores/iberrdrola', video: '', ver: false },
  { img: igsa, url: '/patrocinadores/igsa', video: 'Fzvy5ULWPhg', ver: true },
  { img: jll, url: '/patrocinadores/jll', video: 'oSzZZPLgQUA', ver: true },
  { img: kansas, url: '/patrocinadores/kansas', video: 'deOKzC-eF94', ver: true },
  { img: owens, url: '/patrocinadores/owens', video: '', ver: false },
  { img: solana, url: '/patrocinadores/solana', video: 'tYSWRAKXJ2c', ver: true },
  { img: topmanagment, url: '/patrocinadores/topmanagment', video: 'ierlUKTKFyo', ver: true },
  { img: transtelco, url: '/patrocinadores/transtelco', video: 'DH6f_zT8F-A', ver: true },
  { img: ubicuity, url: '/patrocinadores/ubicuity', video: '', ver: false },
  { img: hermosillo, url: '/patrocinadores/hermosillo', video: 'fCtQIlbcKM8', ver: true },

]

const convencion = [
  {img: gesilicones_bco, url:'/patrocinadores/ge-silicones',video:'',ver:true},
  {img: isosindu_bco, url:'/patrocinadores/isocinou',video:'',ver:true},
]

const Patrocinadores = () => {
  let visualizar_socios = socios.map((datos)=>{
    return (
      <Col xs={{span:8}} md={{span:6}} lg={{span:4}}>
        {datos.ver ? 
        <Link to={datos.url}>
          <img src={datos.img} style={{width:'100%', padding:'15px 8px'}} />
        </Link>
      :
        <img src={datos.img} style={{width:'100%', padding:'15px 8px'}} />}
      </Col>
    )
  })

  let visualizar_socios_convencion = convencion.map((datos)=>{
    return (
      <Col xs={{span:8}} md={{span:6}} lg={{span:6}}>
        {datos.ver ? 
        <Link to={datos.url}>
        <img src={datos.img} style={{width:'100%'}} />
        </Link>
      :
      <img src={datos.img} style={{width:'100%'}} />}
      </Col>
    )
  })

  return(
    <>
    <Row className="back_azul">
          <Col span={20} offset={2}>
          <Row align="middle">
          <Col span={1}>
          <p style={{textAlign:'left'}}><hr className="hr_titulos"/></p>
          </Col>
          <Col span={23}>
          <p className="titulo_footer_patrocinadores color_blanco">PATROCINADORES DEL 4º FORO</p>
          </Col>
        </Row>
        
      </Col>
        <Col span={20} offset={2}>
          <Row justify="center" align="middle">
          {visualizar_socios_convencion}
          </Row>
          <br /><br />
        </Col>
      </Row>
      <Row align="middle">
      <Col span={20} offset={2}>
      <Row align="middle">
          <Col span={1}>
          <p style={{textAlign:'left'}}><hr className="hr_titulos"/></p>
          </Col>
          <Col span={23}>
          <p className="color_azul textos_titulos_secciones" style={{textAlign:'left'}}>PATROCINADORES OFICIALES</p>
          </Col>
        </Row>
      </Col>
      <Col span={20} offset={2}>
        <Row justify="center" align="middle">
        {visualizar_socios}
        </Row>
      </Col>
    </Row>
    </> 
  )
}
export default Patrocinadores;